import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { BASE_URL } from "./config";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DocumentTitle from './DocumentTitle';
const Registration = () => {
  DocumentTitle("REGISTRATION | SYNTH - SOUNDS OF STREETWEAR");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmpassword: "",
  });
  const [ setMessage] = useState("");
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmpassword: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const validateForm = () => {
    let isValid = true;
    const newErrors = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmpassword: "",
    };

    const nameRegex = /^[A-Za-z]+$/;
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First Name is required";
      isValid = false;
    } else if (!nameRegex.test(formData.firstName.trim())) {
      newErrors.firstName = "First Name must contain only letters";
      isValid = false;
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
      isValid = false;
    } else if (!nameRegex.test(formData.lastName.trim())) {
      newErrors.lastName = "Last Name must contain only letters";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@gmail\.com$/;
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = "Please enter a valid email address (e.g., user@gmail.com)";
      isValid = false;
    }


    const phoneRegex = /^[0-9]+$/;
  if (!formData.phone.trim()) {
    newErrors.phone = "Phone Number is required";
    isValid = false;
  } else if (!phoneRegex.test(formData.phone.trim())) {
    newErrors.phone = "Phone Number must contain only digits";
    isValid = false;
  }

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;
 

    if (!formData.password) {
      newErrors.password = " Password is required";
      isValid = false;
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password = "New password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character (@#$%^&+=!)";
      isValid = false;
    }

    if (!formData.confirmpassword) {
      newErrors.confirmpassword = "Confirm password is required";
      isValid = false;
    }else if (formData.password !== formData.confirmpassword) {
      newErrors.confirmpassword = "Confirm password does not match";
      isValid = false;
    }
    // if (!formData.password) {
    //   newErrors.password = "Password is required";
    //   isValid = false;
    // } else if (formData.password.length < 8) {
    //   newErrors.password = "New password must be at least 8 characters long";
    //   isValid = false;
    // }

    // if (formData.password !== formData.confirmpassword) {
    //   newErrors.confirmpassword = "Confirm password does not match";
    //   isValid = false;
    // }
    // if (!formData.confirmpassword) {
    //   newErrors.confirmpassword = "Confirm password is required";
    //   isValid = false;
    // }
    setErrors(newErrors);
    return isValid;
  };
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/'); 
    }
  }, [navigate]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (formData.password !== formData.confirmpassword) {
      setMessage("Passwords do not match");
      return;
    }

    const data = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      password: formData.password,
      confirm_password: formData.confirmpassword,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/register`,
        data
      );
      
      if (response.data.success) {
        toast.success(response.data.message, {
          autoClose: 3000,
        });
      
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          password: "",
          confirmpassword: "",
        });
      } else {
        setMessage(`Registration failed: ${response.data.message}`);
        toast.error(response.data.message, {
          autoClose: 3000,
        });
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      toast.error(error, { autoClose: 3000,});
    }
  };
  return (
    <>
      <div className="page-template-default page page-id-424 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-account woocommerce-page woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner full-width-bg-black">
            <Header />
            <div className="eltdf-content full-width-bg-black">
              <div className="eltdf-content-inner">
                <div className="eltdf-title  eltdf-standard-type eltdf-content-left-alignment breaducm">
                  <div className="eltdf-title-holder">
                    <div className="eltdf-container clearfix full-width-bg-black">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title white-text">
                              <span>Sign Up</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner">
                                  <Link to="/" className="white-text">Home</Link>
                                  <span className="eltdf-delimiter white-text">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current white-text">Sign Up</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eltdf-container eltdf-default-page-template full-width-bg-black">
                <div className="eltdf-container-inner clearfix">
                <div className="eltdf-row-grid-section" >
                  <div className="vc_row wpb_row vc_row-fluid vc_custom_1503483162971">
                    <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-1 vc_col-lg-10">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <h2 className="white-text common">Sign Up</h2>
                            </div>
                          </div>
                          <div
                            className="vc_empty_space"
                          >
                            <span className="vc_empty_space_inner" />
                          </div>
                          <div
                            role="form"
                            className="wpcf7"
                            id="wpcf7-f2345-p1185-o2"
                            lang="en-US"
                            dir="ltr"
                          >
                            <div className="screen-reader-response">
                              <p
                                role="status"
                                aria-live="polite"
                                aria-atomic="true"
                              />
                              <ul />
                            </div>

                            <form
                              onSubmit={handleSubmit}
                              className="wpcf7-form init demo"
                              noValidate="novalidate"
                              data-status="init"
                            >
                          
                              <div>
                                <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                  <div className="eltdf-grid-col-6">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="first-name"
                                    >
                                      <input
                                        type="text"
                                        name="firstName"
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required custom-input"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="First Name"
                                        required
                                        value={formData.firstName}
                                        onChange={handleChange}
                                      />
                                       {errors.firstName && (
                                                    <div className="error-message">
                                                      {errors.firstName}
                                                    </div>
                                       )}
                                    </span>
                                  </div>
                                  <div className="eltdf-grid-col-6">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="last-name"
                                    >
                                      <input
                                        type="text"
                                        name="lastName"
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required custom-input"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="Last Name"
                                        required
                                        value={formData.lastName}
                                        onChange={handleChange}
                                      />
                                       {errors.lastName && (
                                                    <div className="error-message">
                                                      {errors.lastName}
                                                    </div>
                                       )}
                                    </span>
                                  </div>
                                </div>
                                <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                  <div className="eltdf-grid-col-6">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="email"
                                    >
                                      <input
                                        type="email"
                                        name="email"
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email custom-input"
                                        aria-invalid="false"
                                        placeholder="Email"
                                        required
                                        value={formData.email}
                                        onChange={handleChange}
                                      />
                                      {errors.email && (
                                        <div className="error-message">
                                          {errors.email}
                                        </div>
                                      )}
                                    </span>
                                  </div>
                                  <div className="eltdf-grid-col-6">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="phone"
                                    >
                                      <input
                                        type="text"
                                        name="phone"
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required custom-input"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="Phone Number"
                                        required
                                        value={formData.phone}
                                        onChange={handleChange}
                                      />
                                      {errors.phone && (
                                        <div className="error-message">
                                          {errors.phone}
                                        </div>
                                      )}
                                    </span>
                                  </div>
                                  <div className="eltdf-grid-col-6">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="password"
                                    >
                                      <input
                                        type="password"
                                        name="password"
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required custom-input"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="Password"
                                        required
                                        value={formData.password}
                                        onChange={handleChange}
                                      />
                                       {errors.password && (
                                                    <div className="error-message">
                                                      {errors.password}
                                                    </div>
                                       )}
                                    </span>
                                  </div>
                                  <div className="eltdf-grid-col-6">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="confirmpassword"
                                    >
                                      <input
                                        type="password"
                                        name="confirmpassword"
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required custom-input"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="Confirm Password"
                                        required
                                        value={formData.confirmpassword}
                                        onChange={handleChange}
                                      />
                                       {errors.confirmpassword && (
                                                    <div className="error-message">
                                                      {errors.confirmpassword}
                                                    </div>
                                       )}
                                    </span>
                                  </div>
                                </div>
                                <p className="white-text">
                                  If you have no account then
                                  <a
                                    href="/login"
                                    style={{
                                      fontWeight: "bold",
                                      marginLeft: "5px",
                                    }}
                                    className="white-text custom-hover"
                                  >
                                    Sign In
                                  </a>
                                </p>
                                <p className="common sign-reg">
                                  <button
                                    type="submit"
                                    className="woocommerce-button button woocommerce-form-login__submit wp-element-button"
                                    name="registration"
                                    value="registration"
                                  >
                                    Sign Up
                                  </button>
                                </p>
                                
                              </div>
                              <div
                                className="wpcf7-response-output"
                                aria-hidden="true"
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
