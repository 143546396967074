import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "linearicons/dist/web-font/style.css";
import axios from "axios";
import { BASE_URL, BASE_IMAGE_URL } from "./config";
import { fetchCartList } from "../Api/CartApi/CartListApi";
import { deleteFromCart } from "../Api/CartApi/DeletCartApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import DocumentTitle from './DocumentTitle';
import { ClipLoader } from "react-spinners";
const Cart = () => {
  DocumentTitle("CART | SYNTH - SOUNDS OF STREETWEAR");
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");


  useEffect(() => {
    const getCartItems = async () => {
      try {
        const items = await fetchCartList(token);
        setCartItems(items);
      } catch (error) {
        toast.error('Error fetching cart items:', error,{autoClose:3000 });
      } finally {
        setLoading(false);
      }
    };
    
    getCartItems();
  }, [token]);

  const handleDeleteToCart = async (cartId) => {
    try {
      const response = await deleteFromCart(cartId, token);
      if (response.success) {
        toast.success("Product successfully deleted in cart", {
          autoClose: 3000,
        });  
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("There was an error deleting your product.", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("There was an error deleting your product.", {
        autoClose: 3000,
      });
    }
  };

  const handleIncrement = (cart_id, currentQuantity) => {
    const newQuantity = currentQuantity + 1;
    updateQuantity(cart_id, newQuantity);
  };

  const handleDecrement = (cart_id, currentQuantity) => {
    const newQuantity = currentQuantity > 1 ? currentQuantity - 1 : 1;
    updateQuantity(cart_id, newQuantity);
  };

  const updateQuantity = async (cart_id, newQuantity) => {
    
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/update-cart`,
        { cart_id, quantity: newQuantity },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.success) {
        toast.success("Quantity upadted succefully", {
          autoClose: 3000,
        });
        setCartItems((prevItems) => {
          const updatedItems = prevItems.map((item) => {
            if (item.id === cart_id) {
              const updatedItem = { ...item, quantity: newQuantity };
              return updatedItem;
            }
            return item;
          });
          return updatedItems;
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error(response.data.message || "Failed to update quantity",{
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Error updating cart. Please try again.",error,{
        autoClose: 3000,
      });
    }
  };
  if (loading) {
    return (
      <div className="loader-container">
       <ClipLoader size={50} />
      </div>
    );
  }

  return (
    <>
      <div className="page-template-default page page-id-422 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-cart woocommerce-page woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner full-width-bg-black">
            <Header />  
            <div className="eltdf-content full-width-bg-black">
              <div className="eltdf-content-inner ">
                <div className="eltdf-title  eltdf-standard-type eltdf-content-left-alignment breaducm">
                  <div className="eltdf-title-holder">
                    <div className="eltdf-container clearfix ">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title white-text">
                              <span>Cart</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner">
                                  <Link to="/" className="white-text">Home</Link>
                                  <span className="eltdf-delimiter white-text">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current white-text">Cart</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eltdf-container eltdf-default-page-template full-width-bg-black">
                  <div className="eltdf-container-inner clearfix full-width-bg-black">
                    <div className="eltdf-grid-row">
                      <div className="eltdf-page-content-holder eltdf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-notices-wrapper" />
                          <form className="woocommerce-cart-form ">
                            <h2 className="white-text">Shopping Cart</h2>
                            {cartItems.length === 0 ? (
                              <p className="centered-message white-text">Product not found</p>
                            ) : (
                              <table className="shop_table shop_table_responsive"cellSpacing={0}>
                                <thead className="white-text">
                                  <tr>
                                    <th className="product-thumbnail"><span>Image</span></th>
                                    <th className="product-name">Product Name</th>
                                    <th className="product-quantity">Quantity</th>
                                    <th className="product-size">Size</th>
                                    <th className="product-price">Price</th>
                                    <th className="product-remove">
                                      <span className="screen-reader-text">
                                        Remove item
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="white-text">
                                  {cartItems.map((item) => (
                                    <tr  key={item.id}  className="woocommerce-cart-form__cart-item cart_item">
                                      <td className="product-thumbnail">
                                        <Link to={`/product-details/${item.product.product_slug}`}>
                                          <img  width={70}  height={70}  src={`${BASE_IMAGE_URL}/${item.product.primary_image}`}
                                            className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"  alt={item.name}
                                            decoding="async"  loading="lazy"
                                          />
                                        </Link>
                                      </td>
                                      <td className="product-name" data-title="Product">
                                        <Link to={`/product-details/${item.product.product_slug}`} className="white-text custom-hover">
                                          {item.product.product_name}
                                        </Link>
                                      </td>
                                      <td  className="product-quantity"  data-title="Quantity">
                                        <div className="quantity eltdf-quantity-buttons full-width-bg-black">
                                          <span  className="eltdf-quantity-minus lnr lnr-chevron-left white-text"
                                           onClick={() =>  handleDecrement(  item.id,  item.quantity)}
                                           />
                                          {item.quantity}
                                          <span  className="eltdf-quantity-plus lnr lnr-chevron-right white-text"
                                            onClick={() =>   handleIncrement(  item.id,  item.quantity)}
                                          />
                                        </div>
                                      </td>
                                      <td  className="product-price" data-title="Price">
                                        <span className="woocommerce-Price-amount amount">
                                          <bdi>{item.size} </bdi>
                                        </span>
                                      </td>
                                      <td  className="product-price" data-title="Price">
                                        <span className="woocommerce-Price-amount amount">
                                          <bdi>  ₹ {item.product.base_price} </bdi>
                                        </span>
                                      </td>
                                      <td className="product-remove">
                                        <Link  to={`/product-details/${item.id}`} className="remove white-text"  aria-label="Remove this item"
                                          data-product_id={item.id}  data-product_sku={item.sku}
                                          onClick={(e) => {  e.preventDefault();  handleDeleteToCart(item.id);}}
                                        >
                                          ×
                                        </Link>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="eltdf-grid-row">
                      <div className="eltdf-page-content-holder eltdf-grid-col-9">
                        <a className="eltdf-cart-go-back custom-hover white-text" href="/shop"> <i className="fa-solid fa-arrow-left-long white-text"></i> Go Back Shopping</a>
                      </div>
                      <div className="eltdf-page-content-holder eltdf-grid-col-3">
                        <div className="wc-proceed-to-checkout">
                          <Link to="/check-out"  className="checkout-button button alt wc-forward wp-element-button">
                            Proceed to checkout
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
