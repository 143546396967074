import { BASE_URL } from "../../Components/config";
import axios from "axios";
export const fetchCartList = async ( token) => {
    try {
      const response = await axios.get(`${BASE_URL}/cart-list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data.carts;
     
      
    } catch (error) {
      console.error("Error fetching cart items:", error);
       return []; 
    }
  };
