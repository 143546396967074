import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import axios from "axios";
import { fetchCategories } from "../Api/CategoryApi";
// import { addToCart } from "../Api/CartApi/AddToCartApi";
import { BASE_URL, BASE_IMAGE_URL } from "./config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import DocumentTitle from './DocumentTitle';
const Shop = () => {
  DocumentTitle("SHOP | SYNTH - SOUNDS OF STREETWEAR");
  const token = localStorage.getItem("token");
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);

  const [sorting, setSorting] = useState("");
  const [noProductsFound, setNoProductsFound] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [value, setValue] = useState([minPrice, maxPrice]);
  const [loading, setLoading] = useState(true);
  // const navigate = useNavigate();
  const location = useLocation();
  const { cat_slug } = useParams();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sortingValue = queryParams.get("sorting");
    setSorting(sortingValue || "");
  }, [location.search]);

 
  const handleSortingChange = (event) => {
    const sortOption = event.target.value;
    let sortedProducts = [...products];
    if (sortOption === 'low_to_high') {
      sortedProducts.sort((a, b) => a.base_price - b.base_price);
    } else if (sortOption === 'high_to_low') {
      sortedProducts.sort((a, b) => b.base_price - a.base_price);
    }
    else if (sortOption === "") {
      sortedProducts = [...originalProducts];
    }
    setProducts(sortedProducts);
  };
  
    const fetchProducts = async (page = 1) => {
      setLoading(true);
      try {
        const queryParams = `?page=${page}`;
        let url = `${BASE_URL}/product-list-new${queryParams}`;
        if (cat_slug) {
          url += `&${cat_slug}`;
        }
        const response = await axios.get(url);
        if (response.data.success) {
          const { products } = response.data.data;
    
          if (products.data.length > 0) {
            const prices = products.data.map((product) => product.base_price);
            const min = Math.min(...prices);
            const max = Math.max(...prices);
            setProducts(products.data);
            setOriginalProducts(products.data);
            setCurrentPage(products.current_page);
            setTotalItems(products.total);
            setPerPage(products.per_page);
            setTotalPages(products.last_page); 
            setNoProductsFound(false);
            setMinPrice(min);
            setMaxPrice(max);
            setValue([min, max]);
          
          } else {
            setNoProductsFound(true);
            setOriginalProducts([]);
            setMinPrice(0);
            setMaxPrice(0);
            setValue([0, 0]);
            setTotalItems(0);
          }

        } else {
          toast.error("Failed to fetch products.",response.data.message,{
            autoClose: 3000,
          });
        }
      } catch (error) {
        toast.error("Error fetching products.",error,{
          autoClose: 3000,
        });
      } finally {
        setLoading(false);
      }
    };
    
    useEffect(() => {
      fetchProducts(currentPage);
    }, [cat_slug, sorting, currentPage]);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const categoryData = await fetchCategories(token);
        setCategories(categoryData);
      } catch (error) {
        toast.error(error, { autoClose: 3000, });
      }
    };
    fetchCategory();
  }, [token]);

  // const handleAddToCart = async (productId) => {
  //   try {
  //     const response = await addToCart(productId, token, navigate);
  //     if (response.success) {
  //       toast.success("Product successfully added in cart", {
  //         autoClose: 3000,
  //       });
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 3000);
  //     } else {
  //       toast.error("There was an error adding your product", {
  //         autoClose: 2000,
  //       });
  //     }
  //   } catch (error) {
  //     toast.error("Plasse first login then add the product", {
  //       autoClose: 2000,
  //     });
  //   }
  // };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchProducts(page);
  };

  const start = products.length > 0 ? (currentPage - 1) * perPage + 1 : 0;
  const end = products.length > 0 ? Math.min(start + products.length - 1, totalItems): 0;

const handleSliderUpdate = (values) => {
  const [minValue, maxValue] = values;
  setValue(values); 
  if (minValue === minPrice && maxValue === maxPrice) { 
    setProducts(originalProducts);
  } else {
    const filtered = products.filter(
      (product) =>
        product.base_price >= minValue && product.base_price <= maxValue
    );

    setProducts(filtered);
  }
};
if (loading) {
  return (
    <div className="loader-container">
     <ClipLoader size={50} />
    </div>
  );
}
  return (
    <>
      <div className="archive post-type-archive post-type-archive-product theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-shop woocommerce woocommerce-page woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woo-main-page eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-woo-single-switch-image eltdf-woo-single-thumb-on-left-side eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner full-width-bg-black">
            <Header />
            <div className="eltdf-content full-width-bg-black">
              <div className="eltdf-content-inner">
                <div className="eltdf-title  eltdf-standard-type eltdf-content-left-alignment full-width-bg-black breaducm">
                  <div className="eltdf-title-holder">
                    <div className="eltdf-container clearfix">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title white-text">
                              <span>Shop</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner ">
                                  <Link to="/" className="white-text">Home</Link>
                                  <span className="eltdf-delimiter white-text">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current white-text">Shop</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eltdf-container full-width-bg-black">
                  <div className="eltdf-container-inner clearfix">
                    <div className="eltdf-grid-row">
                      <div className="eltdf-page-content-holder eltdf-grid-col-9 eltdf-grid-col-push-3">
                        <div className="woocommerce-notices-wrapper"/>
                        <p className="woocommerce-result-count white-text">
                          Showing {start}–{end} of {totalItems} results
                        </p>
                        <form className="woocommerce-ordering">
                          <select name="orderby" className="orderby low-price"
                            aria-label="Shop order"
                            onChange={handleSortingChange}
                            >
                            <option value="" className="item_text">
                              Select
                            </option>
                            <option value="low_to_high">
                              Sort by price: low to high
                            </option>
                            <option value="high_to_low">
                              Sort by price: high to low
                            </option>
                          </select>
                          <input type="hidden" name="paged" defaultValue={1} />
                        </form>

                        <div className="eltdf-pl-main-holder">
                          {loading ? (
                            <div>
                              <ClipLoader color={"#000"} loading={loading} size={50} />
                            </div>
                          ) : noProductsFound ? (
                            <p className="not"> Product not found </p>
                          ) : (
                            <ul className="products columns-4">
                              {products.map((product) => (
                                <li key={product.id}  className="product type-product">
                                  <div className="eltdf-pl-inner">
                                    <div className="eltdf-pl-image">
                                      <Link to={`/product-details/${product.product_slug}`} >
                                        <img src={`${BASE_IMAGE_URL}/${product.primary_image}`}
                                          className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail arrival-resize-img"
                                          alt={product.product_name} decoding="async" loading="lazy"
                                        />
                                      </Link>
                                    </div>
                                    <div className="eltdf-pl-add-to-cart">
                                      <Link  rel="nofollow" to={`/product-details/${product.product_slug}`}  data-quantity={1}  className="eltdf-button"
                                        // onClick={(e) => {  e.preventDefault();  handleAddToCart(product.id); }}
                                        >
                                        Add to cart
                                        <span className="eltdf-add-to-cart-icon icon-arrows-circle-plus" />
                                      </Link>
                                    </div>
                                    <div className="eltdf-pl-text-wrapper">
                                      <h5 className="eltdf-product-list-title white-text">
                                        <Link  className="custom-hover" to={`/product-details/${product.product_slug}`}>
                                            {product.product_name}
                                        </Link>
                                      </h5>
                                      <div className="eltdf-pl-rating-holder">
                                        <div  className="star-rating"  role="img"  aria-label={`Rated ${product.rating} out of 5`} >
                                          <span  style={{  width: `${product.rating * 20}%`}} >
                                            Rated
                                            <strong className="rating">
                                              {product.rating}
                                            </strong>
                                            out of 5
                                          </span>
                                        </div>
                                      </div>
                                      <span className="price">
                                        <span className="woocommerce-Price-amount amount">
                                          <bdi className="white-text">₹{product.base_price}</bdi>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                        <div className="eltdf-woo-pagination-holder">
                          <div className="eltdf-woo-pagination-inner">
                            <nav className="woocommerce-pagination">
                              <ul className="page-numbers">
                                {currentPage > 1 && (
                                  <li>
                                    <Link className="page-numbers"  onClick={() =>handlePageChange(currentPage - 1)}>
                                      <i className="fa-solid fa-arrow-left-long white-text"></i>
                                    </Link>
                                  </li>
                                )}
                                {Array.from(
                                  { length: totalPages },
                                  (_, index) => index + 1
                                ).map((page) => (
                                  <li key={page}>
                                    <Link className={`page-numbers ${ page === currentPage ? "active-page" : "" }`}
                                      onClick={() => handlePageChange(page)}
                                    >
                                      {page}
                                    </Link>
                                  </li>
                                ))}
                                {currentPage < totalPages && (
                                  <li>
                                    <Link className="page-numbers" onClick={() => handlePageChange(currentPage + 1)}>
                                      <i className="fa-solid fa-arrow-right-long white-text"></i>
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    
                      <div className="eltdf-sidebar-holder eltdf-grid-col-3 eltdf-grid-col-pull-9">
                        <aside className="eltdf-sidebar">
                          {/* <div className="widget eltdf-separator-widget">
                            <div className="eltdf-separator-holder clearfix  eltdf-separator-center eltdf-separator-normal">
                              <div className="eltdf-separator" />
                            </div>
                          </div> */}
                          <div className="widget woocommerce widget_layered_nav woocommerce-widget-layered-nav">
                            <div className="eltdf-widget-title-holder">
                              <h5 className="eltdf-widget-title">categories</h5>
                              <hr></hr>
                            </div>
                            <ul className="woocommerce-widget-layered-nav-list">
                              {categories.map((category) => (
                                <li className="woocommerce-widget-layered-nav-list__item wc-layered-nav-term" key={category.id}>
                                  <a className={`${ location.pathname === `/shop/cat_slug=${category.slug}` ? "currentCat" : ""} custom-hover`}
                                     href={`/shop/cat_slug=${category.slug}`}
                                     title={category.title}>
                                     {category.title}</a>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="widget woocommerce widget_layered_nav woocommerce-widget-layered-nav">
                            <div className="eltdf-widget-title-holder">
                              <h5 className="eltdf-widget-title">Price</h5>
                              <hr></hr>
                            </div>
                               <RangeSlider  min={minPrice} max={maxPrice} step={1} value={value}   onInput={handleSliderUpdate}  className="range-slider .range-slider__thumb range-slider__range"/>
                            <span className="price">
                                <bdi className="white-text"> ₹ {value[0]} - ₹ {value[1]} </bdi>
                            </span>
                          </div>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Shop;
