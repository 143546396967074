import React, { useState, useEffect } from "react";
import axios from "axios";
import { fetchCategories } from "../Api/CategoryApi";
import { BASE_URL } from "./config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { handleLogout } from '../Api/Auth';
import { ClipLoader } from "react-spinners";
const Footer = () => {
  const token = localStorage.getItem("token");
  
  const [categories, setCategories] = useState([]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [footerData, setFooterData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errors, setErrors] = useState({ email: "",});
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const categoryData = await fetchCategories(token);
        setCategories(categoryData);
      } catch (error) {
        toast.error(error);
      }
    };
    fetchCategory();
  }, [token]);

  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/setting`);
        setFooterData(response.data.data.setting);
      } catch (error) {
        toast.error('error fetching footer data.',error);
      }
    };

    fetchFooterData();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      email: "",
    };
    const emailRegex = /^[^\s@]+@gmail\.com$/;
  
    if (!email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!emailRegex.test(email.trim())) {
      newErrors.email = "Please enter a valid email address (e.g., user@example.com)";
      isValid = false;
    } 
    setErrors(newErrors);
    return isValid;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios.post(`${BASE_URL}/subscriptiont`, { email });
  
      if (response.data.success) {
        if (response.data.message && response.data.message.email) {
          toast.error(response.data.message.email[0], {
            autoClose: 3000,
          });
          setMessage(response.data.message.email[0]);
          setEmail('');
        } else {
          toast.success(response.data.message, {
            autoClose: 3000,
          });
          setMessage(response.data.message);
        }
      } else {
        toast.error("Subscription failed.", {
          autoClose: 3000,
        });
        setMessage("Subscription failed.");
      }
    } catch (error) {
      toast.error("An error occurred.", error,{
        autoClose: 3000,
      });
      setMessage("An error occurred.");
    }
  };
  
  if (!footerData) return <div className="loader-container">
  <ClipLoader size={50} />
 </div>;
  return (
    <>
      <footer className="eltdf-page-footer">
        <div className="eltdf-footer-top-holder ">
          <div className="eltdf-footer-top-inner eltdf-grid ">
            <div className="eltdf-grid-row eltdf-footer-top-alignment-left eltdf-light">
              <div className="eltdf-column-content eltdf-grid-col-3">
                <div id="text-2"className="widget eltdf-footer-column-1 widget_text">
                  <div className="eltdf-widget-title-holder"> <h5 className="eltdf-widget-title">{footerData.name}</h5> </div>
                  {/* <div className="textwidget"> <p>{footerData.description}</p> </div> */}
                </div>
                {/* <a
                  className="eltdf-icon-widget-holder eltdf-icon-has-hover"
                  data-hover-color="rgb(167, 169, 175)"
                  href="/#"
                  target="_self"
                  style={{ color: "rgb(255, 255, 255)", fontSize: "17px",  margin: "0px 20px 0px 0px", }}
                >
                  <span className="eltdf-icon-widget fa-solid fa-phone"></span>
                  <span className="eltdf-icon-text">{footerData.phone}</span>
                </a> */}
               
                <a className="eltdf-icon-widget-holder white-text" href="/#" target="_self" >
                  <span className="eltdf-icon-widget fa-solid fa-envelope"></span>
                  <span className="eltdf-icon-text custom-hover">{footerData.email}</span>
                </a>
                <div className="widget eltdf-separator-widget">
                  <div className="eltdf-separator-holder clearfix  eltdf-separator-center eltdf-separator-normal">
                    <div className="eltdf-separator"/>
                  </div>
                </div>
              </div>

              <div className="eltdf-column-content eltdf-grid-col-3">
                <div id="woocommerce_product_categories-2" className="widget eltdf-footer-column-3 woocommerce widget_product_categories">
                  <div className="eltdf-widget-title-holder"> <h5 className="eltdf-widget-title">Important Links</h5> </div>
                  <ul className="product-categories">
                    <li className="cat-item cat-item-38 ">
                      <Link to="/" className="custom-hover">Home</Link>
                    </li>
                    <li className="cat-item cat-item-52">
                      <Link to="/about-us" className="custom-hover">About</Link>
                    </li>
                    <li className="cat-item cat-item-66">
                      <Link to="/contact-us" className="custom-hover">Contact</Link>
                    </li>
                    <li className="cat-item cat-item-66">
                      <Link to="/shop" className="custom-hover">Shop</Link>
                    </li>
                    {isLoggedIn && (
                      <li className="cat-item cat-item-99">
                        <Link to="/my-order" className="custom-hover">My Order</Link>
                      </li>
                    )}
                     {isLoggedIn && (
                      <li className="cat-item cat-item-99">
                        <Link to="/cart" className="custom-hover">My Cart</Link>
                      </li>
                    )}
                    {isLoggedIn && (
                      <li className="cat-item cat-item-99">
                        <Link to="/login" onClick={handleLogout} className="custom-hover">Logout</Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              
              <div className="eltdf-column-content eltdf-grid-col-3">
                <div id="woocommerce_product_categories-2" className="widget eltdf-footer-column-3 woocommerce widget_product_categories">
                  <div className="eltdf-widget-title-holder"> <h5 className="eltdf-widget-title">Categeory</h5> </div>
                  <ul className="product-categories">
                    {categories.map((category) => (
                      <li className="eltdf-bl-item clearfix" key={category.id}>
                        <Link to={`/shop/cat_slug=${category.slug}`}title={category.title} className="custom-hover">{category.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="eltdf-column-content eltdf-grid-col-3">
                <div id="woocommerce_product_categories-2"className="widget eltdf-footer-column-3 woocommerce widget_product_categories">
                  <div className="eltdf-widget-title-holder"><h5 className="eltdf-widget-title">subscription</h5> </div>
                  <form onSubmit={handleSubmit} className="eltdf-grid-col-6 sub" >
                    <div className="eltdf-grid-row eltdf-grid-small-gutter">
                      <div className="eltdf-grid-col-12">
                        <span className="wpcf7-form-control-wrap" data-name="your-name" >
                          <input type="email" name="your-name"size={40}className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email custom-input"
                            aria-invalid="false"
                            value={email}
                            placeholder="Email"
                            onChange={(e) => {setEmail(e.target.value);setMessage("");}} 
                            />
                          {errors.email && ( <div className="error-message"> {errors.email}</div> )}
                        </span>
                      </div>
                    </div>
                    <div className="form-row place-order">
                      <div className="woocommerce-terms-and-conditions-wrapper">
                        <div className="woocommerce-privacy-policy-text" />
                      </div>
                      <button type="submit"  className="woocommerce-button button woocommerce-form-login__submit wp-element-button footer-btn"
                        name="woocommerce_checkout_registration"
                        id="registration"
                        value="Subscribe"
                        data-value="registration"
                      >
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="eltdf-footer-bottom-holder ">
          <div className="eltdf-footer-bottom-inner eltdf-grid">
            <div className="eltdf-grid-row show-footer-bottom-border eltdf-light">
              <div className="eltdf-grid-col-4">
                <div id="text-3" className="widget eltdf-footer-bottom-column-1 widget_text" >
                  <div className="textwidget"><p>{footerData.footer_text}</p></div>
                </div>
              </div>
              <div className="eltdf-grid-col-4"></div>
              <div className="eltdf-grid-col-4">
                <div id="text-4" className="widget eltdf-footer-bottom-column-3 widget_text" >
                  <div className="textwidget">
                    <h6 className="white-text follow"> Follow us </h6>
                  </div>
                </div>
                <a className="eltdf-social-icon-widget-holder eltdf-icon-has-hover eltdf-normal custom-hover white-text" href={`${footerData.instagram}`}>
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
