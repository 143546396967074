import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <>
      <div className="error404 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper eltdf-404-page">
          <div className="eltdf-wrapper-inner">
            <div className="eltdf-content full-width-bg-black">
              <div className="eltdf-content-inner">
                <div className="eltdf-page-not-found">
                  <div className="eltdf-404-title-image">
                    <Link to="/">
                      <img
                        src="../wp-content/uploads/2017/08/logo-img.png"
                        alt="not found"
                      />
                    </Link>
                  </div>
                  <div className="eltdf-404-title-image">
                    <Link to="/">
                      <img
                        src="../wp-content/uploads/2017/08/error-page-img.png"
                        alt="img not found"
                      />
                    </Link>
                  </div>
                  <h2 className="eltdf-page-not-found-title"></h2>
                  <h3 className="eltdf-page-not-found-subtitle"></h3>
                  <p className="eltdf-page-not-found-text white-text">
                    Page Not Found
                  </p>
                  <a itemProp="url"
                    href="/"
                    target="_self"
                    className="eltdf-btn eltdf-btn-small eltdf-btn-solid error-btn"
                  >
                    <span>BACK TO THE HOMEPAGE</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
