import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from "./config";
import { toast } from 'react-toastify';
const InactivityTimer = () => {

    const [timeout, setTimeoutValue] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTimeout = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/session-timeout`);
                setTimeoutValue(response.data.timeout * 1000);    
            } catch (error) {
                toast.error('Error fetching session timeout.', error, {
                    autoClose: 3000,
                  });
            }
        };

        fetchTimeout();
    }, []);

    useEffect(() => {
        if (!timeout) return;

        const events = ['mousemove', 'keydown', 'click', 'scroll'];
        let timer;

        const resetTimer = () => {
            clearTimeout(timer);
            timer = setTimeout(logout, timeout);
        };

        const logout = () => {  
            localStorage.removeItem('id');
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('phone');
            localStorage.removeItem('token');
            navigate('/login');
        };

        events.forEach(event => window.addEventListener(event, resetTimer));

        resetTimer();

        return () => {  
            clearTimeout(timer);
            events.forEach(event => window.removeEventListener(event, resetTimer));
        };
    }, [timeout, navigate]);

   return null;
  
}

export default InactivityTimer

