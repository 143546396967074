import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "elegant-icons/style.css";
import { BASE_URL, BASE_IMAGE_URL } from "./config";
import { useParams, useNavigate } from "react-router-dom";
import { addToCart } from "../Api/CartApi/AddToCartApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
const ProductDetails = () => {
  const { product_slug } = useParams();
  const [productData, setProductData] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeTab, setActiveTab] = useState("description");
  const [rating, setRating] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewDescription, setReviewDescription] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [reviews, setReviews] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSize, setSelectedSize] = useState("");
  const [errors, setErrors] = useState({
    rating: '',
    reviewTitle: '',
    reviewDescription: '',
    name: '',
    email: ''
  });
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

 
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/product-detail/${product_slug}`);
        const product = response.data?.data?.product;
        setProductData(product);
        if (product && product.product_name) {
          document.title = product.product_name + " | SYNTH - SOUNDS OF STREETWEAR";
        } else {
          toast.error("Product name not found in the response", {
            autoClose: 3000,
          });
          document.title = "Product Not Found";
        }
      } catch (error) {
        toast.error("Error fetching product data",error, {
          autoClose: 3000,
        });
      }
    };

    if (product_slug) { 
      fetchProductData();
    } else {
      toast.error("Product slug is undefined", {
        autoClose: 3000,
      });
    }
  }, [product_slug]);

  useEffect(() => {
    const storedName = localStorage.getItem("name");
    const storedEmail = localStorage.getItem("email");

    if (storedName) setName(storedName);
    if (storedEmail) setEmail(storedEmail);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/review-list/${product_slug}`
        );
        if (response.data.success) {
          setReviews(response.data.data.review);
        }
      } catch (error) {
        toast.error("Error fetching reviews", error, {
          autoClose: 3000,
        });
      }
    };

    fetchReviews();
  }, [product_slug, token]);

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/related-product-list/${product_slug}`
        );
        if (response.data.success) {
          setProducts(response.data.data.products);
        }
      } catch (error) {
        toast.error("Error fetching related products", error, {
          autoClose: 3000,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchRelatedProducts();
  }, [product_slug]);

  
  const handleSubmit = async (productId) => {
    if (!selectedSize) {
      toast.error("Please select a size before adding to cart", {
        autoClose: 2000,
      });
      return;
    }
    try {
      const result = await addToCart(productId, selectedSize, token, navigate);
      if (result.success) {
        const message = result.data.carts;
        toast.success("Product successfully added in cart", message, {
          autoClose: 2000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("There was an error adding your product", {
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error("Plasse first login then add the product", {
        autoClose: 3000,
      });
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleClick = (value) => {
    setRating(value);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      rating: '',
      reviewTitle: '',
      reviewDescription: '',
      name: '',
      email: ''
    };
  
    if (!rating) {
      newErrors.rating = 'Rating is required.';
      valid = false;
    }
    const nameRegex = /^[A-Za-z]+$/;
    if (!reviewTitle.trim()) {
      newErrors.reviewTitle = "Review title is required";
      valid = false;
    } else if (!nameRegex.test(reviewTitle.trim())) {
      newErrors.reviewTitle = " Review title must contain only letters";
      valid = false;
    }
    if (!reviewDescription.trim()) {
      newErrors.reviewDescription = "Review description is required";
      valid = false;
    } else if (!nameRegex.test(reviewDescription.trim())) {
      newErrors.reviewDescription = " Review description must contain only letters";
      valid = false;
    }
   
    if (!name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    } else if (!nameRegex.test(name.trim())) {
      newErrors.name = " Name must contain only letters";
      valid = false;
    }
   
    const emailRegex = /^[^\s@]+@gmail\.com$/;
    if (!email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!emailRegex.test(email.trim())) {
      newErrors.email = "Please enter a valid email address (e.g., user@gmail.com)";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  

  const handleSubmitReview = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios.post(
        `${BASE_URL}/add-review`,
        {
          product_id: productData.id,
          rating,
          review_title: reviewTitle,
          review_description: reviewDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        toast.success("Review added successfully", {
          autoClose: 3000,
        });
      } else {
        if (response.data.data && response.data.data.error) {
          toast.error(response.data.data.error, {
            autoClose: 3000,
          });
        } else {
          toast.error("Failed to add review.", {
            autoClose: 3000,
          });
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.data &&
        error.response.data.data.error
      ) {
        toast.error(error.response.data.data.error, {
          autoClose: 3000,
        });
      } else {
        toast.error("An error occurred while adding the review.", {
          autoClose: 3000,
        });
      }
    }
  };



  if (loading) {
    return (
      <div className="loader-container">
        <ClipLoader size={50} />
      </div>
    );
  }
  if (!productData) {
    return  <div className="loader-container">
    <ClipLoader size={50} />
  </div>;
   }
  return (
    <>
      <div className="product-template-default single single-product postid-412 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce woocommerce-page woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woo-single-page eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-woo-single-switch-image eltdf-woo-single-thumb-on-left-side eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner full-width-bg-black">
            <Header />
            <div className="eltdf-content full-width-bg-black">
              <div className="eltdf-content-inner">
                <div className="eltdf-title  eltdf-standard-type eltdf-content-left-alignment breaducm">
                  <div className="eltdf-title-holder">
                    <div className="eltdf-container clearfix full-width-bg-black">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title white-text">
                              <span>Product Details</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner ">
                                  <Link to="/" className="white-text">
                                    Home
                                  </Link>
                                  <span className="eltdf-delimiter white-text">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <Link to="#" className="white-text">
                                    {productData.category.title}
                                  </Link>
                                  <span className="eltdf-delimiter white-text">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current white-text">
                                    {productData.product_name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eltdf-container full-width-bg-black">
                  <div className="eltdf-container-inner clearfix">
                    <div className="woocommerce-notices-wrapper" />
                    <div
                      id="product-412"
                      className="product type-product post-412 status-publish first instock product_cat-summer-nude-collection product_tag-beige product_tag-catalog product_tag-lace has-post-thumbnail shipping-taxable purchasable product-type-simple"
                    >
                      <div className="eltdf-single-product-content">
                        <div className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-4 images">
                          <Swiper
                            // style={{
                            //   "--swiper-navigation-color": "#fff",
                            //   "--swiper-pagination-color": "#fff",
                            // }}
                            spaceBetween={10}
                            navigation={true}
                            thumbs={{
                              swiper:
                                thumbsSwiper && !thumbsSwiper.destroyed
                                  ? thumbsSwiper
                                  : null,
                            }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper2"
                          >
                            <SwiperSlide>
                              <img
                                src={`${BASE_IMAGE_URL}/${productData.primary_image}`}
                                alt="Primary Product"
                              />
                            </SwiperSlide>

                            {productData.images.map((image, index) => (
                              <SwiperSlide key={index}>
                                <img
                                  src={`${BASE_IMAGE_URL}/${image.images}`}
                                  alt="Product"
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>

                          <Swiper
                            onSwiper={setThumbsSwiper}
                            spaceBetween={10}
                            slidesPerView={4}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper"
                          >
                            <SwiperSlide>
                              <img
                                src={`${BASE_IMAGE_URL}/${productData.primary_image}`}
                                alt="Primary Product Thumbnail"
                              />
                            </SwiperSlide>
                            {productData.images.map((image, index) => (
                              <SwiperSlide key={index}>
                                <img
                                  src={`${BASE_IMAGE_URL}/${image.images}`}
                                  alt="Product Thumbnail"
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>

                        <div className="eltdf-single-product-summary">
                          <div className="summary entry-summary">
                            <h2  itemProp="name"  className="eltdf-single-product-title white-text">
                              {productData.product_name}
                            </h2>
                            <p className="price">
                              <span className="woocommerce-Price-amount amount white-text">
                                <bdi>
                                  <span>Price: ₹</span>
                                  {productData.base_price}
                                </bdi>
                              </span>
                            </p>
                            <div className="woocommerce-product-detail_short-description synth-content white-text containerStyle">
                              <p>{productData.description}</p>
                            </div>
                            <div>
                              <span className="white-text" style={{ marginRight: "8px" }}>
                                Select Size:
                              </span>
                              {productData.sizes.split(",").map((size) => (
                                <Link
                                  key={size}
                                  className={`eltdf-size-link white-text size ${
                                    selectedSize === size ? "active" : ""
                                  }`}
                                  to="#"
                                  onClick={() => setSelectedSize(size)}
                                >
                                  {size}
                                </Link>
                              ))}
                            </div>

                            <form  className="cart"  encType="multipart/form-data" >
                              <div className="quantity eltdf-quantity-buttons m">
                                <span className="eltdf-quantity-label">
                                  Quantity
                                </span>
                                1
                              </div>
                              <button
                                type="submit"
                                name="add-to-cart"
                                value={productData.id}
                                className="single_add_to_cart_button button alt wp-element-button"
                                onClick={(e) => { e.preventDefault(); handleSubmit(productData.id);}}
                              >
                                Add to cart
                              </button>
                            </form>

                            <div className="eltdf-single-product-share-wish">
                              {/* <div className="yith-wcwl-add-to-wishlist add-to-wishlist-412  no-icon wishlist-fragment on-first-load">
              <div className="yith-wcwl-add-button">
                <a href="#" className="add_to_wishlist single_add_to_wishlist" data-product-id={productData.id} data-product-type="simple" data-original-product-id={productData.id} data-title="Wishlist" rel="nofollow">
                  <span><i className="fa-regular fa-heart"></i> Wishlist </span>
                </a>
              </div>
            </div> */}
                              <div className="eltdf-woo-social-share-holder">
                                <span className="white-text">Share</span>
                                <div className="eltdf-social-share-holder eltdf-list">
                                  <ul>
                                    <li className="eltdf-facebook-share">
                                      <Link
                                        itemProp="url"
                                        className="eltdf-share-link white-text"
                                        to="#"
                                      >
                                        <i className="fa-brands fa-facebook"></i>
                                      </Link>
                                    </li>
                                    <li className="eltdf-twitter-share">
                                      <Link
                                        itemProp="url"
                                        className="eltdf-share-link white-text"
                                        to="#"
                                      >
                                        <i className="fa-brands fa-twitter"></i>
                                      </Link>
                                    </li>
                                    <li className="eltdf-linkedin-share">
                                      <Link
                                        itemProp="url"
                                        className="eltdf-share-link white-text"
                                        to="#"
                                      >
                                        <i className="fa-brands fa-linkedin-in"></i>
                                      </Link>
                                    </li>
                                    <li className="eltdf-pinterest-share">
                                      <Link
                                        itemProp="url"
                                        className="eltdf-share-link white-text"
                                        to="#"
                                      >
                                        <i className="fa-solid fa-p"></i>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="product_meta">
                              {/* <span className="sku_wrapper">
                                SKU:
                                <span className="sku">{productData.sku}</span>
                              </span> */}
                              <span className="posted_in white-text">
                                Category:
                                <Link to="#" rel="tag" className="white-text custom-hover">
                                  {productData.category.title || ""}
                                </Link>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="woocommerce-tabs wc-tabs-wrapper">
                        <ul className="tabs wc-tabs " role="tablist">
                          <li
                            className={`description_tab ${
                              activeTab === "description" ? "active" : ""
                            }`}
                            id="tab-title-description"
                            role="tab"
                            aria-controls="tab-description"
                            onClick={() => handleTabClick("description")}
                          >
                            <a href="#description" className="white-text">
                              Description
                            </a>
                          </li>
                          <li
                            className={`additional_information_tab ${
                              activeTab === "additional_information"
                                ? "active"
                                : ""
                            }`}
                            id="tab-title-additional_information"
                            role="tab"
                            aria-controls="tab-additional_information"
                            onClick={() =>
                              handleTabClick("additional_information")
                            }
                          >
                            <a href="#specifications" className="white-text">
                              specifications
                            </a>
                          </li>
                          <li
                            className={`reviews_tab ${
                              activeTab === "reviews" ? "active" : ""
                            }`}
                            id="tab-title-reviews"
                            role="tab"
                            aria-controls="tab-reviews"
                            onClick={() => handleTabClick("reviews")}
                          >
                            <a href="#reviews" className="white-text">
                              Reviews
                            </a>
                          </li>
                        </ul>

                        <div
                          className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab full-width-bg-black"
                          id="tab-description"
                          role="tabpanel"
                          aria-labelledby="tab-title-description"
                          style={{
                            display:
                              activeTab === "description" ? "block" : "none",
                          }}
                        >
                          <h2>Description</h2>

                          <p className="white-text">
                            {productData.description}
                          </p>
                        </div>

                        <div
                          className="woocommerce-Tabs-panel woocommerce-Tabs-panel--additional_information panel entry-content wc-tab full-width-bg-black"
                          id="tab-additional_information"
                          role="tabpanel"
                          aria-labelledby="tab-title-additional_information"
                          style={{
                            display:
                              activeTab === "additional_information"
                                ? "block"
                                : "none",
                          }}
                        >
                          <h2>Additional information</h2>

                          <p className="white-text">
                            {productData.specifications}
                          </p>
                        </div>
                        <div
                          className="woocommerce-Tabs-panel woocommerce-Tabs-panel--reviews panel entry-content wc-tab full-width-bg-black"
                          id="tab-reviews"
                          role="tabpanel"
                          aria-labelledby="tab-title-reviews"
                          style={{
                            display: activeTab === "reviews" ? "block" : "none",
                          }}
                        >
                          <div id="reviews" className="woocommerce-Reviews">
                            <div id="comments">
                              <h2 className="woocommerce-Reviews-title">
                                {reviews.length} review
                                {reviews.length !== 1 && "s"} for
                                <span>Sunglasses</span>
                              </h2>
                              {reviews.length > 0 ? (
                                <ol className="commentlist">
                                  {reviews.map((review) => (
                                    <li
                                      key={review.id}
                                      className="comment even thread-even depth-1"
                                      id={`li-comment-${review.id}`}
                                    >
                                      <div
                                        id={`comment-${review.id}`}
                                        className="comment_container"
                                      >
                                        <img
                                          data-del="avatar"
                                          src="../wp-content/uploads/2017/08/nouser.png"
                                          alt="Avatar"
                                          style={{ borderRadius: "50%" }}
                                        />
                                        <div className="comment-text">
                                          <div
                                            className="star-rating white-text"
                                            role="img"
                                            aria-label={`Rated ${review.rating} out of 5`}
                                          >
                                            <span
                                              style={{
                                                width: `${
                                                  (review.rating / 5) * 100
                                                }%`,
                                              }}
                                            >
                                              Rated
                                              <strong className="rating">
                                                {review.rating}
                                              </strong>
                                              out of 5
                                            </span>
                                          </div>
                                          <p className="meta">
                                            <strong className="woocommerce-review__author white-text">
                                              {review.review_title}
                                            </strong>
                                            <span className="woocommerce-review__dash white-text">–</span>
                                            <time
                                              className="woocommerce-review__published-date white-text"
                                              dateTime={new Date(
                                                review.created_at
                                              ).toISOString()}
                                            >
                                              {new Date(
                                                review.created_at
                                              ).toLocaleDateString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                              })}
                                            </time>
                                          </p>
                                          <div className="description">
                                            <p className="white-text">
                                              {review.review_description}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ol>
                              ) : (
                                <p className="white-text">No review found</p>
                              )}
                            </div>

                            {isLoggedIn && (
                              <div id="review_form_wrapper">
                                <div id="review_form">
                                  <div id="respond" className="comment-respond">
                                    <span
                                      id="reply-title"
                                      className="comment-reply-title white-text"
                                    >
                                      Add a review
                                    </span>

                                    <form
                                      onSubmit={handleSubmitReview}
                                      id="commentform"
                                      className="comment-form"
                                    >
                                      <p className="comment-notes white-text">
                                        <span id="email-notes ">
                                          Your email address will not be
                                          published.
                                        </span>
                                        <span className="required-field-message">
                                          Required fields are marked
                                          <span className="required">*</span>
                                        </span>
                                      </p>

                                      <div className="comment-form-rating">
                                        <label  htmlFor="rating" className="white-text">
                                          Your rating&nbsp;
                                          <span className="required">*</span>
                                        </label>
                                        <p className="stars selected">
                                          <span className="white-text">
                                            {[1, 2, 3, 4, 5].map((value) => (
                                              <Link
                                                key={value}
                                                to="#"
                                                className={`star-${value} ${
                                                  value === rating
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleClick(value);
                                                }}
                                              >
                                                {value}
                                              </Link>
                                            ))}
                                          </span>
                                        
                                        </p>
                                        <span className="error-message" style={{ color: 'red' }}>{errors.rating}</span>
                                        <select
                                          name="rating"
                                          id="rating"
                                          
                                          style={{ display: "none" }}
                                          value={rating || ""}
                                          onChange={(e) =>
                                            handleClick(Number(e.target.value))
                                          }
                                        >
                                          <option value="">Rate…</option>
                                          <option value={5}>Perfect</option>
                                          <option value={4}>Good</option>
                                          <option value={3}>Average</option>
                                          <option value={2}>
                                            Not that bad
                                          </option>
                                          <option value={1}>Very poor</option>
                                        </select>
                                      </div>
                                      <p className="comment-form-author">
                                        <label
                                          htmlFor="author"
                                          className="white-text"
                                        >
                                          ReviewTitle&nbsp;
                                          <span className="required">*</span>
                                        </label>
                                        <input
                                          id="review_title"
                                          name="review_title"
                                          type="text"
                                          size="30"
                                          className="custom-input"
                                          
                                          value={reviewTitle}
                                          onChange={(e) =>
                                            setReviewTitle(e.target.value)
                                          }
                                        />
                                        
                                      </p>
                                      <span className="error-message" style={{ color: 'red' }}>{errors.reviewTitle}</span>
                                      <p className="comment-form-comment">
                                        <label
                                          htmlFor="comment"
                                          className="white-text"
                                        >
                                          Your review&nbsp;
                                          <span className="required">*</span>
                                        </label>
                                        <textarea
                                          id="comment"
                                          name="comment"
                                          cols="45"
                                          rows="8"
                                          className="custom-input"
                                          
                                          value={reviewDescription}
                                          onChange={(e) =>
                                            setReviewDescription(e.target.value)
                                          }
                                        ></textarea>
                                          
                                      </p>
                                      <span className="error-message" style={{ color: 'red' }}>{errors.reviewDescription}</span>
                                      <p className="comment-form-author">
                                        <label
                                          htmlFor="author"
                                          className="white-text"
                                        >
                                          Name&nbsp;
                                          <span className="required">*</span>
                                        </label>
                                        <input
                                          id="author"
                                          name="author"
                                          type="text"
                                          size="30"
                                          value={name}
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                          className="white-text custom-input"
                                        />
                                        
                                      </p>
                                      <span className="error-message" style={{ color: 'red' }}>{errors.name}</span>
                                      <p className="comment-form-email">
                                        <label
                                          htmlFor="email"
                                          className="white-text"
                                        >
                                          Email&nbsp;
                                          <span className="required">*</span>
                                        </label>
                                        <input
                                          id="email"
                                          name="email"
                                          type="email"
                                          size="30"
                                          value={email}
                                          onChange={(e) =>
                                            setEmail(e.target.value)
                                          }
                                          className="white-text custom-input" 
                                        />
                                         
                                      </p>
                                      <span className="error-message" style={{ color: 'red' }}>{errors.email}</span>
                                      <p className="form-submit">
                                        <input
                                          name="submit"
                                          type="submit"
                                          id="submit"
                                          className="submit"
                                          value="Submit"
                                        />
                                      </p>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="clear"></div>
                          </div>
                        </div>



                      </div>

                      <section className="related products">
                        <h2 className="white-text">Related products</h2>
                        <ul className="products columns-4">
                          {products.map((product) => (
                            <li key={product.id}
                              className="product type-product post-407 status-publish instock product_cat-wedding-rings product_tag-catalog product_tag-wedding has-post-thumbnail sale shipping-taxable purchasable product-type-simple"
                            >
                              <div className="eltdf-pl-inner">
                                <div className="eltdf-pl-image">
                                  <img
                                    width={300}
                                    height={300}
                                    src={`${BASE_IMAGE_URL}/${product.primary_image}`}
                                    className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail product-resize-img"
                                    alt={product.product_name}
                                    decoding="async"
                                    loading="lazy"
                                  />
                                </div>
                                <div className="eltdf-pl-add-to-cart">
                                  <Link
                                    rel="nofollow"
                                    to={`/product-details/${product.product_slug}`}
                                    data-quantity={1}
                                    data-product_id={product.id}
                                    data-product_sku={product.sku}
                                    className="add_to_cart_button ajax_add_to_cart eltdf-button "
                                    onClick={(e) => {
                                      window.scrollTo({  top: 0, behavior: "smooth", });
                                    }}
                                  >
                                    Add to cart
                                    <span className="eltdf-add-to-cart-icon icon-arrows-circle-plus" />
                                  </Link>
                                </div>
                               
                              </div>
                              <div className="eltdf-pl-text-wrapper">
                                <h5 className="eltdf-product-list-title">
                                  <Link
                                    to={`/product-details/${product.product_slug}`}
                                    onClick={() =>  window.scrollTo({  top: 0, behavior: "smooth", })
                                    }  className="white-text custom-hover"
                                  >
                                    {product.product_name}
                                  </Link>
                                </h5>
                                <span className="price">
                                  <span className="woocommerce-Price-amount amount white-text">
                                    <bdi>
                                      <span className="woocommerce-Price-currencySymbol">₹</span>
                                      {product.base_price}
                                    </bdi>
                                  </span>
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
