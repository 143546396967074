import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { fetchCartList } from "../Api/CartApi/CartListApi";
import { BASE_IMAGE_URL, BASE_URL } from "./config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import DocumentTitle from './DocumentTitle';
import { ClipLoader } from "react-spinners";
const CheckOut = () => {
  DocumentTitle("CHECKOUT | SYNTH - SOUNDS OF STREETWEAR");
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showQrDetails, setShowQrDetails] = useState(false);
  const [formData, setFormData] = useState({
    b_customer_name: "",
    b_address_line1: "",
    b_address_line2: "",
    b_city: "",
    b_state: "",
    b_country: "",
    b_pincode: "",
    b_contact: "",
    b_email: "",
    s_customer_name: "",
    s_address_line1: "",
    s_address_line2: "",
    s_city: "",
    s_state: "",
    s_country: "",
    s_pincode: "",
    s_contact: "",
    s_email: "",
    payment_method: "",
    qrDetails: "",
  });
  const [errors, setErrors] = useState({
    b_customer_name: "",
    b_address_line1: "",
    b_address_line2: "",
    b_city: "",
    b_state: "",
    b_country: "",
    b_pincode: "",
    b_contact: "",
    b_email: "",
    s_customer_name: "",
    s_address_line1: "",
    s_address_line2: "",
    s_city: "",
    s_state: "",
    s_country: "",
    s_pincode: "",
    s_contact: "",
    s_email: "",
    payment_method: "",
  });
  const token = localStorage.getItem('token');

  useEffect(() => {
    const storedName = localStorage.getItem('name');
    const storedEmail = localStorage.getItem('email');
    const storedContact = localStorage.getItem('phone');
    setFormData({
      ...formData,
      b_customer_name: storedName || '',
      b_email: storedEmail || '',
      b_contact: storedContact || '',
    });
  }, []);

 
  useEffect(() => {
    const getCartItems = async () => {
      try {
        const items = await fetchCartList(token);
        setCartItems(items);
      } catch (error) {
        toast.error('Error fetching cart items:', error,{autoClose:3000 });
      } finally {
        setLoading(false);
      }
    };
    
    getCartItems();
  }, [token]);
  // const loadRazorpayScript = () => {
  //   return new Promise((resolve) => {
  //     const script = document.createElement('script');
  //     script.src = 'https://checkout.razorpay.com/v1/checkout.js';
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      b_customer_name: "",
      b_address_line1: "",
      b_address_line2: "",
      b_city: "",
      b_state: "",
      b_country: "",
      b_pincode: "",
      b_contact: "",
      b_email: "",
      s_customer_name: "",
      s_address_line1: "",
      s_address_line2: "",
      s_city: "",
      s_state: "",
      s_country: "",
      s_pincode: "",
      s_contact: "",
      s_email: "",
      payment_method: "",
    };

    const nameRegex = /^[A-Za-z]+$/;
    if (!formData.b_customer_name.trim()) {
      newErrors.b_customer_name = " Name is required";
      isValid = false;
    } else if (!nameRegex.test(formData.b_customer_name.trim())) {
      newErrors.b_customer_name = " Name must contain only letters";
      isValid = false;
    }
    if (!formData.b_address_line1) {
      newErrors.b_address_line1 = "Address1 is required";
      isValid = false;
    }
    if (!formData.b_address_line2) {
      newErrors.b_address_line2 = "Address2 is required";
      isValid = false;
    }
    if (!formData.b_email.trim()) {
      newErrors.b_email = "Email is required";
      isValid = false;
    } 
   
    const phoneRegex = /^[0-9]+$/;
   if (!formData.b_contact.trim()) {
    newErrors.b_contact = "Phone Number is required";
    isValid = false;
   } else if (!phoneRegex.test(formData.b_contact.trim())) {
    newErrors.b_contact = "Phone Number must contain only digits";
    isValid = false;
   }

   const cityRegex = /^[A-Za-z\s]+$/;
    if (!formData.b_city) {
      newErrors.b_city = "City is required";
      isValid = false;
    }
    else if (!cityRegex.test(formData.b_city)) {
      newErrors.b_city = "City should contain only letters";
      isValid = false;
    }
    if (!formData.b_state) {
      newErrors.b_state = "State is required";
      isValid = false;
    }
    if (!formData.b_country) {
      newErrors.b_country = "Country is required";
      isValid = false;
    }

    const pinRegex = /^[0-9]+$/;
    if (!formData.b_pincode) {
      newErrors.b_pincode = "Pincode is required";
      isValid = false;
    }else if (!pinRegex.test(formData.b_pincode.trim())) {
      newErrors.b_pincode = "Pincode must contain only digits";
      isValid = false;
     }

   
    const namesRegex = /^[A-Za-z]+$/;
    if (!formData.s_customer_name.trim()) {
      newErrors.s_customer_name = " Name is required";
      isValid = false;
    } else if (!namesRegex.test(formData.s_customer_name.trim())) {
      newErrors.s_customer_name = " Name must contain only letters";
      isValid = false;
    }
    if (!formData.s_address_line1) {
      newErrors.s_address_line1 = "Address1 is required";
      isValid = false;
    }
    if (!formData.s_address_line2) {
      newErrors.s_address_line2 = "Address2 is required";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@gmail\.com$/;
    if (!formData.s_email.trim()) {
      newErrors.s_email = "Email is required";
      isValid = false;
    } else if (!emailRegex.test(formData.s_email.trim())) {
      newErrors.s_email = "Please enter a valid email address (e.g., user@gmail.com)";
      isValid = false;
    }
    
    const phonesRegex = /^[0-9]+$/;
   if (!formData.s_contact.trim()) {
    newErrors.s_contact = "Phone Number is required";
    isValid = false;
   } else if (!phonesRegex.test(formData.s_contact.trim())) {
    newErrors.s_contact = "Phone Number must contain only digits";
    isValid = false;
   }
    const citysRegex = /^[A-Za-z\s]+$/;
    if (!formData.s_city) {
      newErrors.s_city = "City is required";
      isValid = false;
    }
    else if (!citysRegex.test(formData.s_city)) {
      newErrors.s_city = "City should contain only letters";
      isValid = false;
    }
    if (!formData.s_state) {
      newErrors.s_state = "State is required";
      isValid = false;
    }
    if (!formData.s_country) {
      newErrors.s_country = "Country is required";
      isValid = false;
    }
    const pinsRegex = /^[0-9]+$/;
    if (!formData.s_pincode) {
      newErrors.s_pincode = "Pincode is required";
      isValid = false;
    }else if (!pinsRegex.test(formData.s_pincode.trim())) {
      newErrors.s_pincode = "Pincode must contain only digits";
      isValid = false;
     }
    if (!formData.payment_method) {
      newErrors.payment_method = "payment method selected is required";
      isValid = false;
    }
    if (formData.payment_method === "qr" && !formData.qrDetails) {
      newErrors.qrDetails = "Please enter a transaction ID for QR payment.";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };
  

  const subtotal = cartItems.reduce((acc, item) => {
    return acc + item.quantity * item.product.base_price;
  }, 0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "payment_method") {
    if (value === "qr") {
      setShowQrDetails(true); 
    } else {
      setShowQrDetails(false);
    }
  }
  };
  
  
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
 
  //   if (!validateForm()) {
  //     return;  
  //   }
 
   
  //   if (formData.payment_method === "online") {
  //     try {
  //       const isScriptLoaded = await loadRazorpayScript();
 
  //       if (!isScriptLoaded) {
  //         toast.error('Failed to load Razorpay SDK. Are you online?', { autoClose: 1500 });
  //         return;
  //       }
 
  //       const options = {
  //         key: 'rzp_test_JZzFgiI86rnk9w', 
  //         amount: 50000, 
  //         currency: 'INR',
  //         name: 'KrishnaSoftweb',
  //         description: 'Test Transaction',
  //         image: 'https://i.bb.co/5Y3m33n/test.png',
  //         handler: async function (response) {
  //           try {
  //             const paymentResponse = await axios.post(`${BASE_URL}/order-store`, {
  //               ...formData,
  //               payment_id: response.razorpay_payment_id,
  //             }, {
  //               headers: { Authorization: `Bearer ${token}` },
  //             });
 
  //             if (paymentResponse.data.success) {
  //               const orderNumber = paymentResponse.data.data.order.order_number;
  //               toast.success(`Order placed successfully! Your order number is ${orderNumber}`);
  //               navigate(`/order-success/${orderNumber}`);
  //             } else {
  //               toast.error(paymentResponse.data.message, { autoClose: 3000 });
  //             }
  //           } catch (error) {
  //             toast.error('Failed to place the order. Please try again.', { autoClose: 3000 });
  //           }
  //         },
  //         prefill: {
  //           name: 'Sample Name', 
  //           email: 'sample@example.com',
  //           contact: '7041258952',
  //         },
  //         theme: { color: '#F37254' },
  //       };
 
  //       const rzp = new window.Razorpay(options);
  //       rzp.open();
  //     } catch (error) {
  //       toast.error('Failed to initiate Razorpay', { autoClose: 3000 });
  //     }
  //   }
 
  //   else if (formData.payment_method === "cod") {
  //     try {
  //       const response = await axios.post(`${BASE_URL}/order-store`, formData, {
  //         headers: { Authorization: `Bearer ${token}` },
  //       });
 
  //       if (response.data.success) {
  //         const orderNumber = response.data.data.order.order_number;
  //         toast.success(`Order placed successfully! Your order number is ${orderNumber}`);
  //         navigate(`/order-success/${orderNumber}`);
  //       } else {
  //         toast.error(response.data.message, { autoClose: 3000 });
  //       }
  //     } catch (error) {
  //       toast.error('Failed to place order via COD', { autoClose: 3000 });
  //     }
  //   }
 
  //   else if (formData.payment_method === "qr") {
  //     if (!formData.qrDetails) {
  //       toast.error('Please enter a transaction ID for QR payment.', { autoClose: 3000 });
  //       return;
  //     }
  //     try {
  //       const response = await axios.post(`${BASE_URL}/order-store`, {
  //         ...formData,
  //         txn_id: formData.qrDetails,
  //       }, {
  //         headers: { Authorization: `Bearer ${token}` },
  //       });
  
  //       if (response.data.success) {
  //         const orderNumber = response.data.data.order.order_number;
  //         toast.success(`Order placed successfully! Your order number is ${orderNumber}`);
  //         navigate(`/order-success/${orderNumber}`);
  //       } else {
  //         toast.error(response.data.message, { autoClose: 3000 });
  //       }
  //     } catch (error) {
  //       toast.error('Failed to place order via QR payment method.', { autoClose: 3000 });
  //     }
  //   }
  // };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (cartItems.length === 0) {
      toast.error('Your cart is empty! Please add products to your cart before placing an order.', { autoClose: 3000 });
      return;
    }
    if (formData.payment_method === "qr") {
     
      try {
        const response = await axios.post(`${BASE_URL}/order-store`, {
          ...formData,
          txn_id: formData.qrDetails,
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        if (response.data.success) {
          const orderNumber = response.data.data.order.order_number;
          toast.success(`Order placed successfully! Your order number is ${orderNumber}`);
          navigate(`/order-success/${orderNumber}`);
        } else {
          toast.error(response.data.message, { autoClose: 3000 });
        }
      } catch (error) {
        toast.error('Failed to place order via QR payment method.', { autoClose: 3000 });
      }
    }
  };
  
  if (loading) {
    return (
      <div className="loader-container">
       <ClipLoader size={50} />
      </div>
    );
  }
  
  return (
    <>
      <div className="page-template-default page page-id-423 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-checkout woocommerce-page woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner full-width-bg-black">
            <Header />
            <div className="eltdf-content full-width-bg-black">
              <div className="eltdf-content-inner">
                <div className="eltdf-title  eltdf-standard-type eltdf-content-left-alignment breaducm">
                  <div className="eltdf-title-holder">
                    <div className="eltdf-container clearfix full-width-bg-black">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title white-text">
                              <span>Check Out</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner">
                                  <Link to="/" className="white-text">Home</Link>
                                  <span className="eltdf-delimiter white-text">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current white-text">
                                    Check Out
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eltdf-container eltdf-default-page-template full-width-bg-black">
                  <div className="eltdf-container-inner clearfix">
                    <div className="eltdf-grid-row">
                      <div className="eltdf-page-content-holder eltdf-grid-col-12">
                        <div className="woocommerce">
                          <form  onSubmit={handleSubmit}  className="checkout woocommerce-checkout "  encType="multipart/form-data" >
                            <div className="col2-set" id="customer_details" >
                              <div className="col-1">
                                <div className="woocommerce-billing-fields white-text">
                                  <h3 className="white-text">Shipping Detail</h3>
                                  <div className="woocommerce-billing-fields__field-wrapper">
                                    <p  className="form-row  form-row-wide validate-required" id="billing_first_name_field" data-priority={30}>
                                      <label htmlFor="billing_first_name">
                                        Name&nbsp;
                                        <abbr className="required"title="required">*</abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input type="text" className="input-text custom-input" name="s_customer_name" id="s_customer_name" placeholder="Name"
                                          value={formData.s_customer_name}
                                          onChange={handleChange}
                                        />
                                        {errors.s_customer_name && (<span className="error-message">{errors.s_customer_name}</span> )}
                                      </span>
                                    </p>
                                    <p className="form-row form-row-first validate-required" id="billing_first_name_field" data-priority={10}>
                                      <label htmlFor="billing_first_name">  Email
                                        <abbr className="required" title="required"> * </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input type="email" className="input-text custom-input" name="s_email" id="s_email" placeholder="Email"  autoComplete="given-name"
                                          value={formData.s_email}
                                          onChange={handleChange}
                                        />
                                        {errors.s_email && (
                                          <span className="error-message" >
                                            {errors.s_email}
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                    <p className="form-row form-row-last validate-required" id="billing_last_name_field" data-priority={20} >
                                      <label htmlFor="billing_last_name">
                                        Phone
                                        <abbr  className="required" title="required">*</abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input type="text" className="input-text custom-input" name="s_contact"  id="s_contact" placeholder="Phone" autoComplete="phone"
                                          value={formData.s_contact}
                                          onChange={handleChange}
                                        />
                                        {errors.s_contact && (
                                          <span className="error-message">{errors.s_contact} </span>
                                        )}
                                      </span>
                                    </p>
                                    <p className="form-row form-row-wide" id="billing_company_field" data-priority={30} >
                                      <label htmlFor="billing_company">
                                        Address 1 &nbsp;
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input type="text" className="input-text custom-input" name="s_address_line1" id="s_address_line1" placeholder="Address 1"
                                          autoComplete="organization"
                                          value={formData.s_address_line1}
                                          onChange={handleChange}
                                        />
                                        {errors.s_address_line1 && (
                                          <span className="error-message">
                                            {errors.s_address_line1}
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                    <p className="form-row form-row-wide" id="billing_company_field" data-priority={30} >
                                      <label htmlFor="billing_company">
                                        Address 2 &nbsp;
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input type="text"className="input-text custom-input" name="s_address_line2" id="s_address_line2" placeholder="Address 2"
                                          autoComplete="organization"
                                          value={formData.s_address_line2}
                                          onChange={handleChange}
                                        />
                                        {errors.s_address_line2 && (  <span className="error-message"> {errors.s_address_line2}</span> )}
                                      </span>
                                    </p>
                                    <p  className="form-row form-row-first validate-required" id="billing_first_name_field" data-priority={10} >
                                      <label htmlFor="billing_first_name">
                                        City
                                        <abbr className="required" title="required"> * </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input type="text" className="input-text custom-input" name="s_city"  id="s_city" placeholder="City"autoComplete="given-name"
                                          value={formData.s_city}
                                          onChange={handleChange}
                                        />
                                        {errors.s_city && (  <span  className="error-message"> {errors.s_city} </span> )}
                                      </span>
                                    </p>
                                    <p className="form-row form-row-last validate-required" id="billing_last_name_field" data-priority={20} >
                                      <label htmlFor="billing_last_name">
                                        State
                                        <abbr className="required" title="required">*</abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <select name="s_state" id="s_state" className="checkout-form country_to_state country_select custom-input" autoComplete="address-level1"
                                        
                                          value={formData.s_state}
                                          onChange={handleChange}
                                        >
                                          <option value=""> Select a state </option>
                                          <option value="AN"> Andaman and Nicobar Islands </option>
                                          <option value="AP"> Andhra Pradesh</option>
                                          <option value="AR"> Arunachal Pradesh </option>
                                          <option value="AS">Assam</option>
                                          <option value="BR">Bihar</option>
                                          <option value="CH">Chandigarh</option>
                                          <option value="CT"> Chhattisgarh </option>
                                          <option value="DN"> Dadra and Nagar Haveli </option>
                                          <option value="DD"> Daman and Diu</option>
                                          <option value="DL">Delhi</option>
                                          <option value="GA">Goa</option>
                                          <option value="GJ">Gujarat</option>
                                          <option value="HR">Haryana</option>
                                          <option value="HP"> Himachal Pradesh </option>
                                          <option value="JK">Jammu and Kashmir </option>
                                          <option value="JH">Jharkhand</option>
                                          <option value="KA">Karnataka</option>
                                          <option value="KL">Kerala</option>
                                          <option value="LD">Lakshadweep </option>
                                          <option value="MP"> Madhya Pradesh </option>
                                          <option value="MH"> Maharashtra </option>
                                          <option value="MN">Manipur</option>
                                          <option value="ML">Meghalaya</option>
                                          <option value="MZ">Mizoram</option>
                                          <option value="NL">Nagaland</option>
                                          <option value="OR">Odisha</option>
                                          <option value="PY">Puducherry</option>
                                          <option value="PB">Punjab</option>
                                          <option value="RJ">Rajasthan</option>
                                          <option value="SK">Sikkim</option>
                                          <option value="TN">Tamil Nadu</option>
                                          <option value="TG">Telangana</option>
                                          <option value="TR">Tripura</option>
                                          <option value="UP"> Uttar Pradesh </option>
                                          <option value="UT"> Uttarakhand </option>
                                          <option value="WB"> West Bengal </option>
                                        </select>
                                        {errors.s_state && ( <span className="error-message">{errors.s_state} </span> )}
                                      </span>
                                    </p>
                                    <p className="form-row form-row-first validate-required" id="billing_first_name_field" data-priority={10}>
                                      <label htmlFor="billing_first_name">
                                        Country
                                        <abbr className="required" title="required">*</abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <select className="checkout-form custom-input" name="s_country" id="s_country" autoComplete="country"
                                     
                                          value={formData.s_country}
                                          onChange={handleChange}
                                        >
                                          <option value=""> Select a country </option>
                                          <option value="IN">INDIA</option>
                                        </select>
                                        {errors.s_country && ( <span className="error-message"> {errors.s_country} </span> )}
                                      </span>
                                    </p>
                                    <p className="form-row form-row-last validate-required"id="billing_last_name_field" data-priority={20}>
                                      <label htmlFor="billing_last_name">
                                        Pincode
                                        <abbr className="required" title="required">*</abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input type="text" className="input-text custom-input" name="s_pincode" id="s_pincode" placeholder="Pincode" autoComplete="family-name"
                                          value={formData.s_pincode}
                                          onChange={handleChange}
                                        />
                                        {errors.s_pincode && ( <span className="error-message">{errors.s_pincode} </span> )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 white-text">
                                <h3 className="white-text">Order Summery</h3>
                                {cartItems.length === 0 ? (
                                  <p className="centered-message white-text">Product not found</p>
                                ) : (
                                  <table className="shop_table shop_table_responsive cart woocommerce-cart-form__contents"cellSpacing={0} >
                                    <thead>
                                      <tr>
                                        <th>Image</th>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th>Size</th>
                                        <th>Price</th>
                                      </tr>
                                    </thead>
                                    <tbody className="white-text">
                                      {cartItems.map((item) => (
                                        <tr key={item.id} className="woocommerce-cart-form__cart-item cart_item" >
                                          <td className="product-thumbnail">
                                            <Link to={`/product-details/${item.product.product_slug}`} > 
                                                <img width={70} height={70} src={`${BASE_IMAGE_URL}/${item.product.primary_image}`} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                                alt={item.name} decoding="async" loading="lazy" />
                                            </Link>
                                          </td>
                                          <td className="product-name" data-title="Product">
                                            <Link to={`/product-details/${item.product.product_slug}`}className="white-text custom-hover"> {item.product.product_name} </Link>
                                          </td>
                                          <td className="product-quantity"data-title="Quantity">
                                            <div className="quantity eltdf-quantity-buttons full-width-bg-black">{item.quantity}</div>
                                          </td>
                                          <td className="product-price" data-title="Price" >
                                            <span className="woocommerce-Price-amount amount"> 
                                              <bdi> {item.size}  </bdi>
                                            </span>
                                          </td>
                                          <td className="product-price" data-title="Price" >
                                            <span className="woocommerce-Price-amount amount"> 
                                              <bdi>₹ {item.product.base_price}  </bdi>
                                            </span>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td colSpan={6} className="actions">
                                          <div className="coupon">
                                            <div className="coupon-container">
                                              <input type="text" name="coupon_code"className="input-text custom-input" id="coupon_code" placeholder="Coupon code"/>
                                              <button type="submit" className="button wp-element-button" name="apply_coupon"value="Apply coupon">
                                                Apply coupon
                                              </button>
                                            </div>
                                          </div>
                                          <input type="hidden" id="woocommerce-cart-nonce" name="woocommerce-cart-nonce" defaultValue="ad3603f330" />
                                          
                                        </td>
                                      </tr>
                                      <tr className="cart-subtotal">
                                        <th colSpan="4"> Subtotal </th>
                                        <td><bdi>₹ {subtotal.toFixed(2)} </bdi> </td>
                                      </tr>
                                      <tr className="order-total">
                                        <th colSpan="4"> Grand Total </th>
                                        <td> <bdi > ₹ {subtotal.toFixed(2)}</bdi></td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                )}
                              </div>
                              
                            </div>
                            <div className="col2-set" id="customer_details">
                              <div className="col-1 white-text">
                                <div className="woocommerce-billing-fields">
                                  <h3 className="white-text">Billing Detail</h3>
                                  <div className="woocommerce-billing-fields__field-wrapper">
                                    <p className="form-row  form-row-wide validate-required" id="billing_first_name_field"data-priority={30}>
                                      <label htmlFor="billing_first_name">
                                        Name&nbsp;
                                        <abbr className="required" title="required"> * </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input type="text" className="input-text custom-input"  name="b_customer_name" id="b_customer_name" placeholder="Name"
                                          autoComplete="given-name" value={formData.b_customer_name} onChange={handleChange} />
                                        {errors.b_customer_name && ( <span className="error-message"> {errors.b_customer_name} </span>  )}
                                      </span>
                                    </p>
                                    <p className="form-row form-row-first validate-required"id="billing_first_name_field" data-priority={10}>
                                      <label htmlFor="billing_first_name"> Email
                                        <abbr  className="required" title="required">*</abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input  type="email" className="input-text custom-input" name="b_email"id="b_email" placeholder="Email" autoComplete="given-name"readOnly
                                          value={formData.b_email}onChange={handleChange}
                                          />
                                        {errors.b_email && ( <span  className="error-message"> {errors.b_email} </span>)}
                                      </span>
                                    </p>
                                    <p className="form-row form-row-last validate-required"  id="billing_last_name_field"data-priority={20} >
                                      <label htmlFor="billing_last_name">  Phone <abbr className="required" title="required" >*</abbr> </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input type="text" className="input-text custom-input" name="b_contact" id="b_contact" placeholder="Phone" autoComplete="family-name"
                                          value={formData.b_contact}
                                          onChange={handleChange}
                                        />
                                        {errors.b_contact && ( <span className="error-message">{errors.b_contact} </span> )}
                                      </span>
                                    </p>
                                    <p className="form-row form-row-wide" id="billing_company_field" data-priority={30} >
                                      <label htmlFor="billing_company">  Address 1 &nbsp; </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input type="text" className="input-text custom-input" name="b_address_line1" id="b_address_line1" placeholder="Address 1"
                                          autoComplete="organization"
                                          value={formData.b_address_line1}
                                          onChange={handleChange}
                                        />
                                        {errors.b_address_line1 && ( <span className="error-message"> {errors.b_address_line1} </span>  )}
                                      </span>
                                    </p>
                                    <p  className="form-row form-row-wide"id="billing_company_field" data-priority={30} >
                                      <label htmlFor="billing_company"> Address 2 &nbsp; </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input type="text" className="input-text custom-input" name="b_address_line2" id="b_address_line2"  placeholder="Address 2"
                                          autoComplete="organization"
                                          value={formData.b_address_line2}
                                          onChange={handleChange}
                                        />
                                        {errors.b_address_line2 && (<span className="error-message"> {errors.b_address_line2} </span> )}
                                      </span>
                                    </p>

                                    <p className="form-row form-row-first validate-required"  id="billing_first_name_field" data-priority={10} >
                                      <label htmlFor="billing_first_name"> City <abbr className="required"title="required">*</abbr> </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input  type="text" className="input-text custom-input" name="b_city" id="b_city" placeholder="City" autoComplete="given-name"
                                          value={formData.b_city}
                                          onChange={handleChange}
                                        />
                                        {errors.b_city && ( <span className="error-message"> {errors.b_city} </span> )}
                                      </span>
                                    </p>
                                    <p  className="form-row form-row-last validate-required"  id="billing_last_name_field" data-priority={20} >
                                      <label htmlFor="billing_last_name"> State <abbr className="required" title="required"> * </abbr> </label>
                                      <span className="woocommerce-input-wrapper">
                                        <select name="b_state" id="b_state" className="checkout-form country_to_state country_select custom-input" autoComplete="address-level1"
                                         
                                          value={formData.b_state}
                                          onChange={handleChange}
                                        >
                                          <option value=""> Select a state </option>
                                          <option value="AN">  Andaman and Nicobar Islands </option>
                                          <option value="AP"> Andhra Pradesh </option>
                                          <option value="AR"> Arunachal Pradesh</option>
                                          <option value="AS">Assam</option>
                                          <option value="BR">Bihar</option>
                                          <option value="CH">Chandigarh</option>
                                          <option value="CT"> Chhattisgarh </option>
                                          <option value="DN"> Dadra and Nagar Haveli</option>
                                          <option value="DD"> Daman and Diu </option>
                                          <option value="DL">Delhi</option>
                                          <option value="GA">Goa</option>
                                          <option value="GJ">Gujarat</option>
                                          <option value="HR">Haryana</option>
                                          <option value="HP"> Himachal Pradesh </option>
                                          <option value="JK">Jammu and Kashmir</option>
                                          <option value="JH">Jharkhand</option>
                                          <option value="KA">Karnataka</option>
                                          <option value="KL">Kerala</option>
                                          <option value="LD"> Lakshadweep </option>
                                          <option value="MP"> Madhya Pradesh </option>
                                          <option value="MH"> Maharashtra </option>
                                          <option value="MN">Manipur</option>
                                          <option value="ML">Meghalaya</option>
                                          <option value="MZ">Mizoram</option>
                                          <option value="NL">Nagaland</option>
                                          <option value="OR">Odisha</option>
                                          <option value="PY">Puducherry</option>
                                          <option value="PB">Punjab</option>
                                          <option value="RJ">Rajasthan</option>
                                          <option value="SK">Sikkim</option>
                                          <option value="TN">Tamil Nadu</option>
                                          <option value="TG">Telangana</option>
                                          <option value="TR">Tripura</option>
                                          <option value="UP"> Uttar Pradesh </option>
                                          <option value="UT"> Uttarakhand </option>
                                          <option value="WB">  West Bengal</option>
                                        </select>
                                        {errors.b_state && ( <span className="error-message"> {errors.b_state} </span> )}
                                      </span>
                                    </p>
                                    <p  className="form-row form-row-first validate-required" id="billing_first_name_field"data-priority={10}>
                                      <label htmlFor="billing_first_name"> Country  <abbr className="required" title="required">*</abbr></label>
                                      <span className="woocommerce-input-wrapper">
                                        <select className="checkout-form custom-input" name="b_country" id="b_country" autoComplete="country"
                                          
                                          value={formData.b_country}
                                          onChange={handleChange}
                                        >
                                          <option value=""> Select a country</option>
                                          <option value="IN">INDIA</option>
                                          
                                        </select>
                                        {errors.b_country && ( <span className="error-message"> {errors.b_country} </span>)}
                                      </span>
                                    </p>
                                    <p className="form-row form-row-last validate-required"id="billing_last_name_field" data-priority={20}>
                                      <label htmlFor="billing_last_name"> Pincode<abbr className="required"title="required">*</abbr></label>
                                      <span className="woocommerce-input-wrapper">
                                        <input type="text" className="input-text custom-input" name="b_pincode" id="b_pincode" placeholder="Pincode"
                                          value={formData.b_pincode}
                                          onChange={handleChange}
                                        />
                                        {errors.b_pincode && ( <span className="error-message"> {errors.b_pincode} </span> )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 white-text">
                                <div id="payment" className="woocommerce-checkout-payment">
                                  <ul className="wc_payment_methods payment_methods methods">
                                    {/* <li className="wc_payment_method payment_method_cod">
                                      <input id="payment_method_cod" type="radio"className="input-radio" name="payment_method" value="cod"
                                        data-order_button_text
                                        checked={formData.payment_method === "cod" }
                                        onChange={handleChange}
                                      />
                                      <label htmlFor="payment_method_cod"> Cash on delivery </label>
                                    </li>
                                    {errors.payment_method && ( <span className="error-message"> {errors.payment_method} </span> )}

                                    <li className="wc_payment_method payment_method_online">
                                      <input id="payment_method_online" type="radio"className="input-radio" name="payment_method"
                                      value="online"
                                        data-order_button_text
                                        checked={formData.payment_method === "online"}
                                        onChange={handleChange}
                                      />
                                      <label htmlFor="payment_method_online">  Online Payment  </label>
                                    </li>
                                    {errors.payment_method && ( <span className="error-message"> {errors.payment_method}  </span> )} */}

                                    <li className="wc_payment_method payment_method_qr">
                                      <input id="payment_method_qr" type="radio"className="input-radio" name="payment_method"
                                      value="qr"
                                        checked={formData.payment_method === "qr"}
                                        onChange={handleChange}
                                      />
                                      <label htmlFor="payment_method_qr">QR Code</label>
                                    </li>
                                    {errors.payment_method && ( <span className="error-message"> {errors.payment_method}  </span> )}
                                    {showQrDetails && (
                                       <div className="load">
                                         <img  src="../wp-content/uploads/2017/08/qr.jfif"  alt="Scan QR Code for Payment"className="qr-img"/>
                                          <input
                                            type="text"
                                            placeholder="Enter Trancation Id"
                                            className="custom-input"
                                            value={formData.qrDetails || ""}
                                            onChange={(e) => setFormData({ ...formData, qrDetails: e.target.value })}
                                           />
                                           {errors.qrDetails && (  <span className="error-message">{errors.qrDetails}</span> )}
                                        </div>
                                         )}
                                  </ul>
                                  <div className="form-row place-order">
                                    <div className="woocommerce-terms-and-conditions-wrapper">
                                      <div className="woocommerce-privacy-policy-text" />
                                    </div>
                                    <button  type="submit"  className="button alt wp-element-button"  name="woocommerce_checkout_place_order" id="place_order"  value="Place order"  data-value="Place order"  >
                                       Place order
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckOut;
