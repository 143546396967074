import React,{useState, useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import DocumentTitle from "./DocumentTitle";
import { ClipLoader } from "react-spinners";
const AboutUs = () => {
  DocumentTitle("ABOUT US | SYNTH - SOUNDS OF STREETWEAR");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
    {loading ? ( 
        <div className="loader-container">
        <ClipLoader size={50} />
       </div> 
      ) : (
      <div className="page-template page-template-full-width page-template-full-width-php page page-id-530 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-header-standard-right-position eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner">
            <Header />
            <div className="eltdf-content">
              <div className="eltdf-content-inner">
                <div className="eltdf-full-width">
                  <div className="eltdf-full-width-inner">
                    <div className="eltdf-grid-row">
                      <div className="eltdf-page-content-holder eltdf-grid-col-12">
                        <section className="wpb-content-wrapper full-width-bg-black my-class">
                          <div className="vc_row wpb_row vc_row-fluid vc_row-o-content-middle vc_row-flex founder">
                            <div className="wpb_column vc_column_container vc_col-lg-6 vc_col-sm-6">
                                <img src="../wp-content/uploads/2017/07/founder.jpeg" alt="founder"/>
                            </div>
                            <div className="wpb_column vc_column_container vc_col-lg-6 vc_col-sm-6">
                              <div className="eltdf-eh-item">
                                <div className="eltdf-eh-item-inner">
                                  <div className="eltdf-eh-item-content eltdf-eh-custom-827136">
                                    <div className="wpb_text_column wpb_content_element  vc_custom_1502287365390 content-size">
                                      <div className="wpb_wrapper">
                                      <h2 className="eltdf-st-title white-text"> Founder’s Note - </h2>
                                        <div  className="eltdf-section-title-holder justify">
                                          <p className="eltdf-st-text white-text">
                                             From a young age, I was driven by the need to break free from the ordinary. That desire to think beyond the box and carve my own path led me to pursue a lifelong passion, fashion. 
                                             Synth was born out of that same spirit of boldness and creativity. It’s more than a clothing brand, it’s a reflection of the culture and the pulse of a generation that refuses to be defined by the ordinary.
                                             Our vision is to evolve into a lifestyle that resonates with our generation, a brand that mirrors the rhythm of the streets, the boldness of self-expression, and the freedom to define who we are.
                                              This is only the beginning. 
                                          </p>
                                        </div>
                                        <div className="eltdf-section-title-holder justify">
                                          <p className="eltdf-st-text white-text">Manya Manuja </p>
                                        </div>
                                        <div  className="eltdf-section-title-holder justify">
                                          <p className="eltdf-st-text white-text"> Founder , Shop Synth </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vc_row wpb_row vc_row-fluid vc_row-o-content-middle vc_row-flex vision reverse-columns">
                            <div className="wpb_column vc_column_container vc_col-lg-6 vc_col-sm-6">
                              <div className="eltdf-eh-item">
                                <div className="eltdf-eh-item-inner">
                                  <div className="eltdf-eh-item-content eltdf-eh-custom-827136">
                                      <div className="wpb_text_column wpb_content_element  vc_custom_1502287365390 content-size">
                                        <div className="wpb_wrapper">
                                        <h2 className="eltdf-st-title white-text"> Our Vision </h2>
                                          <div className="eltdf-section-title-holder">
                                            <p className="eltdf-st-text white-text">
                                              At Synth, we are driven by the Three Ts:  <b className="head">Trust, Transparency, and Timelessness.</b>
                                            </p>
                                          </div>
                                          <div className="eltdf-section-title-holder justify">
                                            <p className="eltdf-st-text white-text">
                                               <b className="head">Trust</b> is the foundation of our relationship with our customers. We are committed to creating clothing that not only looks great but also delivers on quality, ensuring that every piece you wear feels like a promise kept.
                                            </p>
                                          </div>
                                          <div className="eltdf-section-title-holder justify">
                                            <p className="eltdf-st-text white-text">
                                              <b className="head">Transparency</b> : From sourcing to production, every step is open, honest, and accessible, reflecting our dedication to ethical practices.
                                            </p>
                                          </div>
                                          <div className="eltdf-section-title-holder justify">
                                            <p className="eltdf-st-text white-text">
                                                <b className="head">Timelessness</b> : Our designs transcend trends, offering pieces that last, both in style and durability.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="wpb_column vc_column_container vc_col-lg-6 vc_col-sm-6">
                              <img src="../wp-content/uploads/2017/07/vision.jpg" alt="vision"/>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
        
          </div>
        </div>
      </div>
     )}

    </>
  );
};

export default AboutUs;
