
import './App.css';
import CheckOut from './Components/CheckOut';
import ContactUs from './Components/ContactUs';
import Main from './Components/Main';
import Shop from './Components/Shop';
import {BrowserRouter as Router, Routes,Route} from 'react-router-dom'

import AboutUs from './Components/AboutUs';
import Registration from './Components/Registration';
import Login from './Components/Login';
import Cart from './Components/Cart';
import Setting from './Components/Setting';
import MyOrder from './Components/MyOrder';
import ProductDetails from './Components/ProductDetails';
import ForgotPassword from './Components/ForgotPassword';
import { ProtectedRoute } from './ProtectedRoute';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrderInvoice from './Components/OrderInvoice';
import OrderSuccess from './Components/OrderSuccess';
import ResetPassword from './Components/ResetPassword';
import ErrorPage from './Components/ErrorPage';
import React from 'react';
import InactivityTimer from './Components/InactivityTimer';


function App() {
  return (
    <>

     <ToastContainer />
   <Router>
   <InactivityTimer />
      <Routes>
        <Route path='/registration' element={<Registration/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/' element={<Main/>}/>
        <Route path='/shop' element={<Shop/>}/>
        <Route path='/shop/:cat_slug' element={<Shop/>}/>
        <Route path='/check-out' element={<ProtectedRoute element={<CheckOut />} />}/>
        <Route path='/contact-us' element={<ContactUs/>}/>
        <Route path='/forgot-password' element={<ForgotPassword/>}/>
        <Route path="/reset-password-link/:token" element={<ResetPassword />} />
        <Route path='/about-us' element={<AboutUs/>}/>
        <Route path='/product-details/:product_slug' element={<ProductDetails/>}/>
        <Route path='/cart' element={<ProtectedRoute element={<Cart />} />}/>
        <Route path='/setting' element={<ProtectedRoute element={<Setting />} />}/>
        <Route path='/my-order' element={<ProtectedRoute element={<MyOrder />} />}/>
        <Route path='/order-invoice/:orderNumber' element={<ProtectedRoute element={<OrderInvoice />} />}/>
        <Route path='/order-success/:orderNumber' element={<ProtectedRoute element={<OrderSuccess />} />}/>

        <Route path='*' element={<ErrorPage />} />
       
      </Routes>
    




    </Router>

    </>
  );
}


export default App;

