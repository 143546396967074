import React,{ useState, useEffect} from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { BASE_URL } from "./config";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DocumentTitle from './DocumentTitle';
const Login = () => {
  DocumentTitle("LOGIN | SYNTH - SOUNDS OF STREETWEAR");
const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: ''});
  const [message, setMessage] = useState('');
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [errors, setErrors] = useState({
    email: "",
    password: "",
   
  });
  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      email: "",
      password: "",
    };

    const emailRegex = /^[^\s@]+@gmail\.com$/;
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = "Please enter a valid email address (e.g., user@gmail.com)";
      isValid = false;
    }
    
    // if (!formData.password) {
    //   newErrors.password = "Password is required";
    //   isValid = false;
    // } else if (formData.password.length < 8) {
    //   newErrors.password = "Password must be at least 8 characters long";
    //   isValid = false;
    // }
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;
    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password = "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character (@#$%^&+=!)";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      localStorage.setItem('redirectAfterLogin', window.location.pathname);
      navigate('/'); 
    }
  }, [navigate]);
  
   const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    } 
    const data = {
      email: formData.email,
      password: formData.password
    };

    try {
      const response = await axios.post(`${BASE_URL}/login`, data, {
        headers: { 'Content-Type': 'application/json' }
      });  
      
      if (response.data.success) {
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('id', response.data.data.user.id);
        localStorage.setItem('name', response.data.data.user.name);
        localStorage.setItem('email', response.data.data.user.email);
        localStorage.setItem('phone', response.data.data.user.phone);
        const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/';
        localStorage.removeItem('redirectAfterLogin');
        toast.success("User login successfully", {
          autoClose: 1500,
        });
        navigate(redirectUrl);
        setFormData({  email: '', password: ''});
        
      } else {
        setMessage(`Login failed: ${response.data.message}`);
        toast.error(response.data.message, {
          autoClose: 1500,
        });
      }
    } catch (error) {
      toast.error('Invalid Credential', {
        autoClose: 1500,
      });
      setMessage('Invalid Credential.');
    }
  };
 
  return (
    <>
    
      <div className=" page-template-default page page-id-424 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-account woocommerce-page woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner full-width-bg-black">
            <Header />
            <div className="eltdf-content full-width-bg-black">
              <div className="eltdf-content-inner ">
                <div  className="eltdf-title  eltdf-standard-type eltdf-content-left-alignment breaducm">
                  <div className="eltdf-title-holder">
                    <div className="eltdf-container clearfix full-width-bg-black">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title white-text">
                              <span>Sign In</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner">
                                  <Link to="/" className="white-text">Home</Link>
                                  <span className="eltdf-delimiter white-text">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current white-text">Sign In</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eltdf-container eltdf-default-page-template full-width-bg-black">
                  <div className="eltdf-container-inner clearfix ">
                    <div className="eltdf-grid-row">
                      <div className="eltdf-page-content-holder eltdf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-notices-wrapper" />
                          <div  className="eltdf-woocommerce-account-login-form white-text">
                            <h2 className="white-text common">Sign In</h2>
                            
                            <form  onSubmit={handleSubmit}  className="wpcf7-form init demo" noValidate="novalidate" data-status="init">
                                <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                  <div className="eltdf-grid-col-12">
                                    <span  className="wpcf7-form-control-wrap"  data-name="email">
                                      <input
                                        type="email"
                                        name="email"
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required custom-input"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="Email"
                                        required
                                        value={formData.email}
                                        onChange={handleChange}
                                      /> 
                                      {errors.email && ( <div className="error-message">{errors.email}</div>)}
                                    </span>
                                  </div>
                                </div>
                                <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                  <div className="eltdf-grid-col-12">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="password"
                                    >
                                      <input
                                        type="password"
                                        name="password"
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text  wpcf7-validates-as-required custom-input"
                                        aria-invalid="false"
                                        placeholder="Password"
                                        required
                                        value={formData.password}
                                        onChange={handleChange}
                                      />
                                       {errors.password && (
                                                    <div className="error-message">
                                                      {errors.password}
                                                    </div>
                                       )}
                                    </span>
                                  </div>
                                </div>
                               <div className="wpcf7-response-output"aria-hidden="true"/>
                                <p className="form-row">
                                <label className="woocommerce-form__label woocommerce-form__label-for-checkbox woocommerce-form-login__rememberme">
                                  <input  className="woocommerce-form__input woocommerce-form__input-checkbox"
                                    name="rememberme"
                                    type="checkbox"
                                    id="rememberme"
                                    defaultValue="forever"
                                  />
                                  <span>Remember me</span>
                                </label> 
                                </p>
                                 <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                  <div className="eltdf-grid-col-12">
                                   <div className="eltdf-grid-row">
                                   <div className="eltdf-grid-col-2 log">
                                   <button
                                     type="submit"
                                     className="woocommerce-button button woocommerce-form-login__submit wp-element-button"
                                     name="login"
                                     value="Log in"
                                     >
                                       Sign in
                                  </button>
                                   </div>
                                   <div className="eltdf-grid-col-10 log">
                                   <a className="woocommerce-button button woocommerce-form-login__submit wp-element-button"  href="/forgot-password">
                                         Forgot Password?
                                  </a>
                                   </div>
                                   </div>
                                  </div>
                                </div>
                               <div> 
                               </div>
                               <div className="eltdf-grid-row eltdf-grid-small-gutter gutter">
                                  <div className="eltdf-grid-col-12">
                                    <p>
                                    If you have no account then&nbsp;
                                    <a href="/registration" className="white-text custom-hover">
                                      <b>Sign Up</b>
                                    </a>
                                  </p>
                                </div>
                               </div>
                               {message && ( <div className="error-message common"> {message}</div> )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
