import React from "react";

const CancellationPolicy = () => {
  return (
    <div className="full-width-bg-black">
      <div className="eltdf-row-grid-section" style={{marginBottom:'-1px'}}>
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="eltdf-section-title-holder cancellation-header" >
            <div className="eltdf-st-subtitle-holder">
              <h6 className="eltdf-st-subtitle">
                <span className="white-text">Our Policy</span>
              </h6>
            </div>
            <h2 className="eltdf-st-title">
              <span className="white-text">Cancellation policy</span>
            </h2>
            <h6 className="white-text cancellation-h6">
              Full refund if you cancel it before the order is accepted by us.
              For any queries on cancellations reach out to us via chat
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancellationPolicy;
