import axios from 'axios';
import { BASE_URL } from '../../Components/config';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const addToCart = async (productId,selectedSize,token,navigate) => {
    try {
      if (!token) {
        localStorage.setItem('redirectAfterLogin', window.location.pathname);
        navigate('/login');
        return;
      }
     
      const response = await axios.post(
        `${BASE_URL}/add-to-cart`,
        {
          product_id: productId,
          size: selectedSize,
          quantity: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;


    } catch (error) {
      toast.error("Error adding product to cart:", error, { autoClose: 2000,});
      throw error;
    }
  };