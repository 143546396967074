import React, { useState } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { BASE_URL , reset_password_link } from "./config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
   
    const emailRegex = /^[^\s@]+@gmail\.com$/;
    let newErrors = {};
    let isValid = true;

    if (!email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!emailRegex.test(email.trim())) {
      newErrors.email = "Please enter a valid email address (e.g., user@gmail.com)";
      isValid = false;
    }

    setError(newErrors.email || ""); 

    if (!isValid) return; 
    try {
      const response = await axios.post(`${BASE_URL}/forget-password`, {
        email,
        base_url: reset_password_link 
      });

      if (response.data.success) {
        localStorage.setItem('email', response.data.data.email);
        toast.success(response.data.message, { autoClose: 3000,});
        setError("");
      }else {
        setSuccess("");
      }
    } catch (error) {
      const errorMessage =
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : "An unexpected error occurred.";
  
      setError(errorMessage);
      setSuccess("");
      toast.error(errorMessage, {
        autoClose: 3000,
      });
    }
  };
  return (
    <>
      <div className="page-template-default page page-id-424 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-account woocommerce-page woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner full-width-bg-black">
            <Header />
            <div className="eltdf-content full-width-bg-black">
              <div className="eltdf-content-inner">
                <div className="eltdf-title eltdf-standard-type eltdf-content-left-alignment breaducm full-width-bg-black">
                  <div className="eltdf-title-holder">
                    <div className="eltdf-container clearfix">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title white-text">
                              <span>Forgot Password</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner">
                                  <Link to="/" className="white-text">Home</Link>
                                  <span className="eltdf-delimiter white-text">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current white-text">
                                    Forgot Password
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eltdf-container eltdf-default-page-template full-width-bg-black">
                  <div className="eltdf-container-inner clearfix">
                    <div className="eltdf-grid-row">
                      <div className="eltdf-page-content-holder eltdf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-notices-wrapper" />
                          <div className="eltdf-woocommerce-account-login-form">
                            <h2  className="white-text common"> Forgot Password</h2>
                            <form onSubmit={handleSubmit} className="wpcf7-form init demo" noValidate="novalidate" data-status="init" >
                              <div>
                                <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                  <div className="eltdf-grid-col-12">
                                    <span className="wpcf7-form-control-wrap">
                                      <input type="text"  size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required custom-input" placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value) }
                                      />
                                       {error && ( <div className="error-message"> {error} </div>)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <button type="submit"className="woocommerce-button button woocommerce-form-login__submit wp-element-button"> Send Email </button>
                            </form>
                            {success && ( <p className="success-message">{success}</p> )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
