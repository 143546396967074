import React from "react";

const UniquieOffering = () => {
  return (
    <div className="full-width-bg-black">
      <div className="eltdf-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1503063920838">
          <div className="eltdf-section-title-holder cancellation-header">
            <div className="eltdf-st-subtitle-holder">
              <h6 className="eltdf-st-subtitle white-text">
                <span>Orders and delivery</span>
              </h6>
            </div>
            <h2 className="eltdf-st-title">
              <span className="white-text">Our Unique Offerings</span>
            </h2>
          </div>
          <div className="wpb_column vc_column_container vc_col-sm-8 vc_col-lg-4 vc_col-md-offset-0 vc_col-md-4 vc_col-sm-offset-2">
            <div className="vc_column-inner vc_custom_1502180798394">
              <div className="wpb_wrapper">
                <div className="eltdf-iwt clearfix eltdf-iwt-icon-top eltdf-iwt-icon-medium">
                  <div className="eltdf-iwt-icon">
                    <span className="eltdf-icon-shortcode eltdf-normal eltdf-icon-medium">
                      <i className="fa-solid fa-location-dot white-text"></i>
                    </span>
                  </div>
                  <div className="eltdf-iwt-content">
                    <h5 className="eltdf-iwt-title">
                      <span className="eltdf-iwt-title-text white-text">
                        Delivery across India by <b>Shop.Synth</b>
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wpb_column vc_column_container vc_col-sm-8 vc_col-lg-4 vc_col-md-offset-0 vc_col-md-4 vc_col-sm-offset-2">
            <div className="vc_column-inner vc_custom_1502180808448">
              <div className="wpb_wrapper">
                <div className="eltdf-iwt clearfix eltdf-iwt-icon-top eltdf-iwt-icon-medium">
                  <div className="eltdf-iwt-icon">
                    <span className="eltdf-icon-shortcode eltdf-normal eltdf-icon-medium">
                      <i className="fa-regular fa-star white-text"></i>
                    </span>
                  </div>
                  <div className="eltdf-iwt-content">
                    <h5 className="eltdf-iwt-title">
                      <span className="eltdf-iwt-title-text white-text">
                        Free delivery on all orders within India
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wpb_column vc_column_container vc_col-sm-8 vc_col-lg-4 vc_col-md-offset-0 vc_col-md-4 vc_col-sm-offset-2">
            <div className="vc_column-inner vc_custom_1502180818266">
              <div className="wpb_wrapper">
                <div className="eltdf-iwt clearfix eltdf-iwt-icon-top eltdf-iwt-icon-medium">
                  <div className="eltdf-iwt-icon">
                    <span className="eltdf-icon-shortcode eltdf-normal eltdf-icon-medium">
                      <i className="fa-solid fa-bag-shopping white-text"></i>
                    </span>
                  </div>
                  <div className="eltdf-iwt-content">
                    <h5 className="eltdf-iwt-title">
                      <span className="eltdf-iwt-title-text white-text">
                        All orders will be delivered by <b>Shop.Synth</b>
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniquieOffering;
