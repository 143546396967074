import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { useParams, Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "./config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DocumentTitle from './DocumentTitle';
const ResetPassword = () => {
  DocumentTitle("RESET PASSWORD | SYNTH - SOUNDS OF STREETWEAR");
  const { token } = useParams();
  

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({ password: '', confirmPassword: '' });
  const navigate = useNavigate();
  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    } else {
      setError("Email not found in localStorage.");
    }
  }, []);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { password: '', confirmPassword: '' };
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;
    if (!password) {
      newErrors.password = 'Password is required';
      isValid = false;
    } else if (!passwordRegex.test(password)) {
      newErrors.password = "New password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character (@#$%^&+=!)";
      isValid = false;
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = 'Confirm Password is Required';
      isValid = false;
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      try {
        const response = await axios.post(`${BASE_URL}/submit-reset-password-form`, {
          token,
          email,
          password,
          password_confirmation: confirmPassword
        
        });
  
        if (response.data.success) {
            toast.success(response.data.message, {
              autoClose: 3000,
            });
          setSuccess(response.data.message);
          setError("");
          navigate('/login')
        } else {
          setSuccess("");
          toast.error(response.data.message, {
            autoClose: 3000,
          });
        }
      } catch (error) {
        toast.error(error, { autoClose: 3000, });
      } 
    } else {
      toast.error('Form is invalid. Errors: ',errors, { autoClose: 3000, });
    }
  };

  return (
    <>
      <div className="eltdf-header-standard">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner full-width-bg-black">
            <Header />
            <div className="eltdf-content full-width-bg-black">
              <div className="eltdf-content-inner">
                <div className="eltdf-title eltdf-standard-type eltdf-content-left-alignment breaducm">
                  <div className="eltdf-title-holder">
                    <div className="eltdf-container clearfix full-width-bg-black">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title white-text">
                              <span>Reset Password</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner">
                                  <Link to="/" className="white-text">Home</Link>
                                  <span className="eltdf-delimiter white-text">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current white-text">
                                    Reset Password
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eltdf-container eltdf-default-page-template full-width-bg-black">
                  <div className="eltdf-container-inner clearfix">
                    <div className="eltdf-grid-row">
                      <div className="eltdf-page-content-holder eltdf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-notices-wrapper" />
                          <div className="eltdf-woocommerce-account-login-form">
                            <h2  className="white-text common">
                              Reset Password
                            </h2>
                            <form  onSubmit={handleSubmit}  className="wpcf7-form init demo" noValidate="novalidate" data-status="init">
                              <div>
                                <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                <div className="grid-col-12">
                                     <input type="email" size={40}  placeholder="Email" value={email} readOnly className="wpcf7-form-control wpcf7-text custom-input"
                                      />
                                      
                                </div>
                                  <div className="eltdf-grid-col-12">
                                    <span className="wpcf7-form-control-wrap">
                                      <input
                                        type="password"
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required custom-input"
                                        placeholder="New Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                      />
                                     {errors.password && (
                                        <div className="error-message">
                                          {errors.password}
                                        </div>
                                      )}
                                    </span>
                                  </div>
                                  <div className="eltdf-grid-col-12">
                                    <span className="wpcf7-form-control-wrap">
                                      <input
                                        type="password"
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required custom-input"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                      />
                                      {errors.confirmPassword && (
                                        <div className="error-message">
                                          {errors.confirmPassword}
                                        </div>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <button type="submit" className="woocommerce-button button woocommerce-form-login__submit wp-element-button">
                                Reset Password
                              </button>
                            </form>
                            {error && <p className="error-message">{error}</p>}
                            {success && ( <p className="success-message">{success}</p> )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
