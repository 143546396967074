import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "./Footer";
import Header from "./Header";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { fetchBanner } from "../Api/BannerApi";
import { fetchCategories } from "../Api/CategoryApi";
import { BASE_IMAGE_URL, BASE_URL } from "./config";
// import { addToCart } from "../Api/CartApi/AddToCartApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {  Link } from "react-router-dom";
import DocumentTitle from './DocumentTitle';
import UniquieOffering from "./UniquieOffering";
import CancellationPolicy from "./CancellationPolicy";
// import FounderNote from "./FounderNote";

const Main = () => {
  DocumentTitle("HOME | SYNTH - SOUNDS OF STREETWEAR");
  const token = localStorage.getItem("token");
  const [banners, setBanners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [arrivals, setArrivals] = useState([]);
  const [loading, setLoading] = useState(true);
  // const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bannerData = await fetchBanner(token);
        setBanners(bannerData);
      } catch (error) {
        toast.error(error,{
          autoClose: 3000,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const categoryData = await fetchCategories(token);
        setCategories(categoryData);
      } catch (error) {
        toast.error( error,{ autoClose: 3000, });
      }
    };
    fetchCategory();
  }, [token]);

  useEffect(() => {
    const fetchArrivalProducts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/arrival-product-list`);
        if (response.data.success) {
          setArrivals(response.data.data.arrivals);
        }
      } catch (error) {
        toast.error('Error fetching arrival products.', error, { autoClose: 3000, });
      }
    };

    fetchArrivalProducts();
  }, []);

  // const handleAddToCart = async (productId) => {
  //   try {
  //     const response = await addToCart(productId, token, navigate);
  //     if (response.success) {
  //       toast.success("Product successfully added in cart", {
  //         autoClose: 3000,
  //       });
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 3000);
  //     } else {
  //       toast.error( "There was an error adding your product",{
  //         autoClose: 3000,
  //       })
  //     }
  //   } catch (error) {
  //     toast.error("Plasse first login then add the product", {
  //       autoClose: 3000,
  //     });
  //   }
  // };
  if (loading) {
    return (
      <div className="loader-container">
       <ClipLoader size={50} />
      </div>
    );
  }
  return (
    <>
      <div className="home page-template page-template-full-width page-template-full-width-php page page-id-381 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-top-bar-enabled eltdf-header-standard eltdf-header-standard-right-position eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-top-bar-light eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner">
            <Header />
            <div className="eltdf-content ">
              <div className="eltdf-content-inner">
                <div className="eltdf-full-width">
                  <div className="eltdf-full-width-inner">
                    <div className="eltdf-grid-row full-width-bg-black">
                      <div className="eltdf-page-content-holder eltdf-grid-col-12">
                        <section className="wpb-content-wrapper">
                          <div className="vc_row wpb_row vc_row-fluid vc_custom_1503563565318 vc_row-has-fill">
                              <div className="wpb_column vc_column_container vc_col-sm-12 full-width-bg-black">
                                <div className="vc_column-inner vc_custom_1503561231409">
                                  <div className="wpb_wrapper">
                                    {loading ? (
                                      <div className="loader-container">
                                        <ClipLoader size={50} />
                                      </div>
                                    ) : (
                                      <Swiper
                                        slidesPerView={1}
                                        spaceBetween={20}
                                        loop={true}
                                        pagination={{ clickable: true }}
                                        navigation={true}
                                        modules={[Pagination, Navigation]}
                                        className="mySwiper"
                                      >
                                        {banners.map((banner) => (
                                          <SwiperSlide key={banner.id}>
                                            <div className="slide">
                                              <Link to='/shop'>
                                              <img  src={`${BASE_IMAGE_URL}/${banner.image}`}  alt="Banner"/>
                                              </Link>
                                            </div>
                                          </SwiperSlide>
                                        ))}
                                      </Swiper>
                                    )}
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div className="full-width-bg-black">
                          <div className="eltdf-row-grid-section">
                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1503499391457">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div  className="eltdf-section-title-holder common">
                                      <div className="eltdf-st-subtitle-holder">
                                        <h6 className="eltdf-st-subtitle white-text" >
                                          <span>Best Fashion categories</span>
                                        </h6>
                                      </div>
                                      <h2 className="eltdf-st-title white-text" >
                                        <span>Best categories </span>
                                      </h2>
                                    </div>
                                    <div className="eltdf-blog-list-holder eltdf-bl-standard eltdf-bl-three-columns eltdf-bl-normal-space cat-img" >
                                      <div className="eltdf-bl-wrapper" >
                                        <ul className="eltdf-blog-list" >
                                          {categories.map((category) => (
                                            <li className="eltdf-bl-item clearfix" key={category.id}>
                                              <div className="eltdf-bli-inner">
                                                <div className="eltdf-post-image">
                                                  <a itemProp="url" href={`/shop/cat_slug=${category.slug}`} title={category.title}>
                                                    <img src={`${BASE_IMAGE_URL}/${category.image}`}alt={category.title} decoding="async"
                                                      className="category-resize-img attachment-full size-full wp-post-image"
                                                    />
                                                  </a>
                                                </div>
                                                <div className="eltdf-bli-content" >
                                                  <h3 itemProp="name" className="entry-title eltdf-post-title white-text">
                                                    <Link itemProp="url" to={`/shop/cat_slug=${category.slug}`} title={category.title} className="custom-hover">
                                                      {category.title}
                                                    </Link>
                                                  </h3>
                                                  <div className="eltdf-bli-info"></div>
                                                  <div className="eltdf-bli-excerpt" >
                                                    <div className="eltdf-post-read-more-button">
                                                      <Link itemProp="url" to={`/shop/cat_slug=${category.slug}`}
                                                        target="_self" style={{ fontSize: 11, fontWeight: 400, }}
                                                        className="eltdf-btn eltdf-btn-medium eltdf-btn-simple eltdf-btn-icon eltdf-blog-list-button eltdf-icon-white"
                                                      >
                                                        <span className="white-text custom-hover">
                                                          Read more
                                                        </span>
                                                        <i className="fa-solid fa-arrow-right-long"></i>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                          <div className="full-width-bg-black">
                          <div className="eltdf-row-grid-section full-width-bg-black">
                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1502796023597">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="eltdf-section-title-holder common">
                                      <div className="eltdf-st-subtitle-holder">
                                        <h6 className="eltdf-st-subtitle white-text">
                                          <span>Best Fashion Deals</span>
                                        </h6>
                                      </div>
                                      <h2 className="eltdf-st-title white-text">
                                        <span>New Arrivals Products</span>
                                      </h2>
                                      <p className="eltdf-st-text white-text">
                                        Discover the newest additions to our
                                        collection that have just landed. From
                                        sleek designs to vibrant colors, these
                                        pieces are crafted to perfection to
                                        elevate your style and meet your needs.
                                        Whether you’re looking for something
                                        chic, trendy, or timeless, our new
                                        arrivals have something for everyone.
                                      </p>
                                    </div>
                                    
                                    <div className="eltdf-pl-holder eltdf-standard-layout eltdf-normal-space eltdf-four-columns eltdf-info-below-image">
                                      <div className="eltdf-pl-outer">
                                        {arrivals.map((item) => (
                                          <div key={item.product.id} className="eltdf-pli">
                                            <div className="eltdf-pli-inner">
                                              <div className="eltdf-post-image">
                                                <Link to={`/product-details/${item.product.product_slug}`}>
                                                  <img src={`${BASE_IMAGE_URL}/${item.product.primary_image}`} alt={item.product.product_name} className="arrival-resize-img"/>
                                                </Link>
                                              </div>
                                              <div className="eltdf-pli-add-to-cart">
                                                <Link  rel="nofollow" to={`/product-details/${item.product.product_slug}`} data-quantity={1}
                                                  className="eltdf-button"
                                                  // onClick={(e) => {  e.preventDefault(); handleAddToCart( item.product.id);
                                                  // }}
                                                >
                                                  Add to cart
                                                  <span className="eltdf-add-to-cart-icon icon-arrows-circle-plus"></span>
                                                </Link>
                                              </div>
                                            </div>
                                            <div className="eltdf-pli-text-wrapper common">
                                              <h5 itemProp="name" className="entry-title eltdf-pli-title white-text">
                                                <Link itemProp="url" to={`/product-details/${item.product.product_slug}`} className="custom-hover">
                                                  {item.product.product_name}
                                                </Link>
                                              </h5>
                                              <div className="eltdf-pli-price">
                                                <span className="woocommerce-Price-amount amount">
                                                  <bdi> ₹{item.product.base_price} </bdi>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                          <UniquieOffering/>
                         <CancellationPolicy/>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
